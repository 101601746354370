<template>
  <v-container
    fluid
    class="light-blue lighten-5 pb-16 px-1 container"
  >
    <!------------------------------------STEPPER-------------------------------------->
    <v-row class="mb-0 d-flex justify-center align-center">
      <v-col
        cols="12"
        sm="11"
        md="9"
        lg="9"
        class="pb-0 mb-0"
      >
        <v-card class="white px-auto pb-0 mb-0">
          <v-stepper>
            <v-stepper-header>
              <v-stepper-step
                step="1"
                complete
                complete-icon="mdi-airplane"
              >
                TRIP INFO
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step
                step="2"
                complete
                complete-icon="mdi-airplane"
              >
                CHOOSE PLAN
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step step="3">
                PURCHASE PLAN
              </v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step step="4">
                DOWNLOAD YOUR POLICY
              </v-stepper-step>
            </v-stepper-header>
          </v-stepper>
        </v-card>
      </v-col>
    </v-row>
    <v-row
      v-if="loading"
      class="mt-12"
    >
      <v-col class="d-flex justify-center">
        <ellipse-progress
          :loading="loading"
          :size="180"
          img-src="src/assets/Travel-Icon.png"
          thickness="5"
          empty-thickness="3"
          line-mode="out 5"
          legend="false"
          animation="rs 700 1000"
          font-size="1.5rem"
          color="#00A1B7"
          empty-color-fill="transparent"
          filename="Travel-Icon.png"
        >
        </ellipse-progress>
      </v-col>
    </v-row>
    <!-----------------------------------------TITLE-------------------------------------->
    <div v-if="!loading">
      <v-row class="d-flex justify-center align-center mt-0">
        <v-col
          class="d-inline-flex"
          cols="12"
          sm="11"
          md="9"
        >
          <v-col
            cols="10"
            class="d-flex justify-start align-center px-0"
          >
            <div
              v-if="mobileDevice"
              class="title-text-mobile"
            >
              Choose a travel plan
            </div>
            <div
              v-if="tabletDevice"
              class="title-text-tablet"
            >
              Choose a travel plan
            </div>
            <div
              v-if="desktopDevice"
              class="title-text"
            >
              Choose a travel plan
            </div>
          </v-col>
          <v-col
            cols="2"
            class="d-flex justify-end align-center pr-0"
          >
            <v-btn
              class="font-weight-bold pa-0"
              color="primary"
              text
              large
              @click="
                $router.go(-1);
                clickedFieldGlobal('editInfo', 33, 'American Travel Survey v1');
              "
            >
              <v-icon
                left
                :large="!mobileDevice"
              >
                mdi-pencil
              </v-icon>
              <span
                :class="
                  mobileDevice ? 'edit-txt-mobile ml-n1 mr-n6' : 'edit-txt'
                "
              >Edit Info</span>
            </v-btn>
          </v-col>
        </v-col>
      </v-row>
      <!--------------------------------------PLAN CARDS------------------------------------>
      <v-row class="d-flex justify-center mt-0">
        <v-col
          cols="12"
          sm="11"
          md="9"
          lg="9"
          class="d-flex justify-space-between"
        >
          <v-card
            v-model="premium"
            class="pa-4 font-weight-bold secondary-border"
            outlined
            :class="
              premiumSelected ? 'selected white--text' : 'secondary--text'
            "
            width="32%"
            @click="
              scrollToDisclosures();
              clickedFieldGlobal(
                'premiumPlanCard',
                34,
                'American Travel Survey v1'
              );
            "
          >
            <div :class="mobileDevice ? 'plan-title-mobile' : 'plan-title'">
              Premium
            </div>
            <div :class="mobileDevice ? 'plan-title-mobile' : 'plan-title'">
              ${{ premPrice }}
            </div>
            <div :class="mobileDevice ? 'plan-sub-mobile' : 'plan-sub'">
              total cost
            </div>
          </v-card>
          <v-card
            v-model="preferred"
            class=" pa-4 font-weight-bold primary-border"
            outlined
            :class="
              preferredSelected ? 'selected white--text' : 'primary--text'
            "
            width="32%"
            @click="
              scrollToDisclosures();
              clickedFieldGlobal(
                'preferredPlanCard',
                35,
                'American Travel Survey v1'
              );
            "
          >
            <div :class="mobileDevice ? 'plan-title-mobile' : 'plan-title'">
              Preferred
            </div>
            <div :class="mobileDevice ? 'plan-title-mobile' : 'plan-title'">
              ${{ prefPrice }}
            </div>
            <div :class="mobileDevice ? 'plan-sub-mobile' : 'plan-sub'">
              total cost
            </div>
          </v-card>
          <v-card
            v-model="standard"
            class=" pa-4 font-weight-bold tertiary-border"
            outlined
            :class="standardSelected ? 'selected white--text' : 'travel--text'"
            width="32%"
            @click="
              scrollToDisclosures();
              clickedFieldGlobal(
                'standardPlanCard',
                36,
                'American Travel Survey v1'
              );
            "
          >
            <div :class="mobileDevice ? 'plan-title-mobile' : 'plan-title'">
              Standard
            </div>
            <div :class="mobileDevice ? 'plan-title-mobile' : 'plan-title'">
              ${{ stndPrice }}
            </div>
            <div :class="mobileDevice ? 'plan-sub-mobile' : 'plan-sub'">
              total cost
            </div>
          </v-card>
        </v-col>
      </v-row>
      <!-------------------------------------PLAN DETAILS-------------------------------------->
      <v-row class="d-flex justify-center">
        <v-col
          class="d-inline-flex pt-0"
          cols="12"
          sm="11"
          md="9"
          lg="9"
        >
          <v-col
            cols="4"
            class="pt-0 text-center"
          >
            <div :class="mobileDevice ? 'plan-details-mobile' : ''">
              An enhanced travel insurance plan designed for more expensive
              travel, including cruises and tours. Get the highest available
              benefit limits with amplified coverage for certain trip
              emergencies. Coverage for Pre-Existing Medical Conditions is only
              available with this plan.
            </div>
          </v-col>
          <v-col
            cols="4"
            class="pt-0 text-center"
          >
            <div :class="mobileDevice ? 'plan-details-mobile' : ''">
              The Preferred travel insurance plan includes the same coverages
              and assistance services as the Standard plan, plus specific
              coverages for Sporting Equipment. Great for ski, golf, hiking
              trips, international travel and more.
            </div>
          </v-col>
          <v-col
            cols="4"
            class="pt-0 text-center"
          >
            <div :class="mobileDevice ? 'plan-details-mobile' : ''">
              This basic travel insurance plan includes coverages for Trip
              Cancellation, Trip Interruption, Baggage, Medical and Dental
              Coverage, and more, plus 24/7 assistance services. Ideal for
              travel within the US, including flights, road trips and vacation
              rentals..
            </div>
          </v-col>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center">
        <v-col
          class="d-flex justify-center"
          cols="4"
          sm="3"
        >
          <div class="text-center">
            <a
              class="font-weight-bold text-center"
              href="https://www.csatravelprotection.com/certpolicy.do?product=GGA300"
              target="_new"
            >
              <span
                class="underline primary--text"
                :class="mobileDevice ? 'covg-txt-mobile' : ''"
                @click="
                  clickedFieldGlobal(
                    'policyDescriptionLinkPremium',
                    37,
                    'American Travel Survey v1'
                  )
                "
              >View Description of Coverage/Policy</span>
            </a>
          </div>
        </v-col>
        <v-col
          class="d-flex justify-center"
          cols="4"
          md="3"
        >
          <div class="text-center">
            <a
              class="font-weight-bold text-center"
              href="https://www.csatravelprotection.com/certpolicy.do?product=GGA200"
              target="_new"
            >
              <span
                class="underline primary--text text-center"
                :class="mobileDevice ? 'covg-txt-mobile' : ''"
                @click="
                  clickedFieldGlobal(
                    'policyDescriptionLinkPreferred',
                    38,
                    'American Travel Survey v1'
                  )
                "
              >View Description of Coverage/Policy</span>
            </a>
          </div>
        </v-col>
        <v-col
          class="d-flex justify-center"
          cols="4"
          sm="3"
        >
          <div class="text-center">
            <a
              class="font-weight-bold text-center"
              href="https://www.csatravelprotection.com/certpolicy.do?product=GGA100"
              target="_new"
            >
              <span
                class="underline primary--text"
                :class="mobileDevice ? 'covg-txt-mobile' : ''"
                @click="
                  clickedFieldGlobal(
                    'policyDescriptionLinkStandard',
                    39,
                    'American Travel Survey v1'
                  )
                "
              >View Description of Coverage/Policy</span>
            </a>
          </div>
        </v-col>
      </v-row>
      <!----------------------------------------Insurance Coverages---------------------------------->
      <v-row justify="center">
        <v-col
          class="d-flex justify-center font-weight-bold mb-0 pb-0"
          :class="mobileDevice ? 'covg-title-mobile' : 'covg-title'"
        >
          Insurance coverage
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col
          class="d-flex justify-center mt-0 pt-0"
          :class="mobileDevice ? 'undwr-txt-mobile' : 'undwr-txt'"
        >
          Underwritten by Generali U.S. Branch
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col
          class="d-flex justify-center mt-12 pt-0 text-center"
          :class="mobileDevice ? 'undwr-txt-mobile' : 'undwr-txt'"
        >
          Benefit limits listed below are per person. <br />
          Plan limits also apply.
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center align-center mb-0 pb-0">
        <v-col
          cols="12"
          sm="11"
          md="9"
          lg="9"
          class="d-inline-flex pa-0"
        >
          <v-col
            cols="4"
            class="premium d-flex justify-center border-top-left"
            :class="mobileDevice ? 'plan-type-mobile' : 'plan-type'"
          >
            Premium
          </v-col>
          <v-col
            cols="4"
            class="preferred d-flex justify-center"
            :class="mobileDevice ? 'plan-type-mobile' : 'plan-type'"
          >
            Preferred
          </v-col>
          <v-col
            cols="4"
            class="standard d-flex justify-center"
            :class="mobileDevice ? 'plan-type-mobile' : 'plan-type'"
          >
            Standard
          </v-col>
        </v-col>
      </v-row>
      <!------------------------------------COVID COVERAGE---------------------------------->
      <v-row class="d-flex justify-center my-0">
        <v-col
          cols="12"
          sm="11"
          md="9"
          lg="9"
          class="d-flex justify-center neutral text-center"
          :class="mobileDevice ? 'covd-title-mobile' : 'covd-title'"
          style="border: 1px solid #e0e0e0;"
        >
          Coverage for COVID-19 sickness
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center mt-0">
        <v-col
          cols="11"
          sm="11"
          md="9"
          lg="9"
          class="text-center"
          :class="mobileDevice ? 'covid-sub-mobile' : 'covid-sub'"
          style="border: 1px solid #e0e0e0;"
        >
          If you, a family member or a traveling companion are diagnosed with
          COVID-19 before or during your trip, and meet the requirements for
          coverage due to sickness, you can be covered for Trip Cancellation,
          Trip Interruption, Travel Delay, Medical & Dental, and Emergency
          Assistance & Transportation, in addition to our 24/7 Emergency
          Assistance services.
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center">
        <v-col
          cols="11"
          sm="11"
          md="9"
          lg="9"
          class="d-inline-flex pa-0"
        >
          <v-col
            class="d-flex justify-center border-right"
            style="background-color: white;"
          >
            <v-icon
              color="secondary"
              x-large
            >
              mdi-check-bold
            </v-icon>
          </v-col><v-col
            class="d-flex justify-center  border-right"
            style="background-color: white;"
          >
            <v-icon
              color="primary"
              x-large
            >
              mdi-check-bold
            </v-icon>
          </v-col><v-col
            class="d-flex justify-center"
            style="background-color: white;"
          >
            <v-icon
              color="travel"
              x-large
            >
              mdi-check-bold
            </v-icon>
          </v-col>
        </v-col>
      </v-row>
      <!------------------------------------TRIP CANCELLATION---------------------------------->
      <v-row class="d-flex justify-center">
        <v-col
          cols="12"
          sm="11"
          md="9"
          lg="9"
          class="d-flex justify-center neutral"
          style="border-top: 1px solid #e0e0e0; border-bottom: 1px solid #e0e0e0;"
        >
          <div :class="mobileDevice ? 'covd-title-mobile' : 'covd-title'">
            Trip Cancellation
          </div>
          <div class="help">
            <v-tooltip
              top
              color="secondary"
              max-width="250px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  color="primary"
                  dark
                  :class="mobileDevice ? 'mr-n4' : ''"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-help-circle
                </v-icon>
              </template>
              <span>Provides reimbursement for unused, non-refundable, prepaid trip
                costs if a covered reason causes you to cancel your trip. There
                are up to 21 covered reasons, including sickness, injury or
                death of you, a traveling companion or non-traveling family
                member, flight delay due to adverse weather, terrorist acts and
                extension of a school year.</span>
            </v-tooltip>
          </div>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center">
        <v-col
          cols="12"
          sm="11"
          md="9"
          lg="9"
          class="d-inline-flex pa-0"
        >
          <v-col
            class="d-flex justify-center border-right"
            style="background-color: white;"
            cols="4"
          >
            <div
              class="d-flex justify-center text-center secondary--text"
              :class="mobileDevice ? 'plan-title-mobile' : 'plan-title'"
            >
              100%
            </div>
          </v-col><v-col
            class="d-flex justify-center border-right"
            style="background-color: white;"
            cols="4"
          >
            <div
              class="d-flex justify-center text-center primary--text"
              :class="mobileDevice ? 'plan-title-mobile' : 'plan-title'"
            >
              100%
            </div>
          </v-col><v-col
            class="d-flex justify-center"
            style="background-color: white;"
            cols="4"
          >
            <div
              class="d-flex justify-center text-center travel--text"
              :class="mobileDevice ? 'plan-title-mobile' : 'plan-title'"
            >
              100%
            </div>
          </v-col>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center mt-0">
        <v-col
          cols="12"
          sm="11"
          md="9"
          lg="9"
          class="d-inline-flex pa-0"
        >
          <v-col
            class="d-flex justify-center border-right"
            style="background-color: white;"
            cols="4"
          >
            <div
              class="d-flex justify-center text-center"
              :class="mobileDevice ? 'plan-sub-mobile' : 'plan-sub'"
            >
              of trip cost insured
            </div>
          </v-col><v-col
            class="d-flex justify-center border-right"
            style="background-color: white;"
            cols="4"
          >
            <div
              class="d-flex justify-center text-center"
              :class="mobileDevice ? 'plan-sub-mobile' : 'plan-sub'"
            >
              of trip cost insured
            </div>
          </v-col><v-col
            class="d-flex justify-center"
            style="background-color: white;"
            cols="4"
          >
            <div
              class="d-flex justify-center text-center"
              :class="mobileDevice ? 'plan-sub-mobile' : 'plan-sub'"
            >
              of trip cost insured
            </div>
          </v-col>
        </v-col>
      </v-row>
      <!------------------------------------TRIP Interruption---------------------------------->
      <v-row class="d-flex justify-center">
        <v-col
          cols="12"
          sm="11"
          md="9"
          lg="9"
          class="d-flex justify-center neutral"
          style="border-top: 1px solid #e0e0e0; border-bottom: 1px solid #e0e0e0;"
        >
          <div :class="mobileDevice ? 'covd-title-mobile' : 'covd-title'">
            Trip Interruption
          </div>
          <div class="help">
            <v-tooltip
              top
              color="secondary"
              max-width="250px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  color="primary"
                  dark
                  :class="mobileDevice ? 'mr-n4' : ''"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-help-circle
                </v-icon>
              </template>
              <span>Provides reimbursement for unused, non-refundable, pre-paid
                trip costs if your trip is interrupted due to a covered event.
                Also provides reimbursement for additional transportation costs
                to return home or rejoin your group.</span>
            </v-tooltip>
          </div>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center">
        <v-col
          cols="12"
          sm="11"
          md="9"
          lg="9"
          class="d-inline-flex pa-0"
        >
          <v-col
            class="d-flex justify-center border-right"
            style="background-color: white;"
            cols="4"
          >
            <div
              class="d-flex justify-center text-center secondary--text"
              :class="mobileDevice ? 'plan-title-mobile' : 'plan-title'"
            >
              175%
            </div>
          </v-col><v-col
            class="d-flex justify-center border-right"
            style="background-color: white;"
            cols="4"
          >
            <div
              class="d-flex justify-center text-center primary--text"
              :class="mobileDevice ? 'plan-title-mobile' : 'plan-title'"
            >
              150%
            </div>
          </v-col><v-col
            class="d-flex justify-center"
            style="background-color: white;"
            cols="4"
          >
            <div
              class="d-flex justify-center text-center travel--text"
              :class="mobileDevice ? 'plan-title-mobile' : 'plan-title'"
            >
              125%
            </div>
          </v-col>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center mt-0">
        <v-col
          cols="12"
          sm="11"
          md="9"
          lg="9"
          class="d-inline-flex pa-0"
        >
          <v-col
            class="d-flex justify-center border-right"
            style="background-color: white;"
            cols="4"
          >
            <div
              class="d-flex justify-center text-center"
              :class="mobileDevice ? 'plan-sub-mobile' : 'plan-sub'"
            >
              of trip cost insured
            </div>
          </v-col><v-col
            class="d-flex justify-center border-right"
            style="background-color: white;"
            cols="4"
          >
            <div
              class="d-flex justify-center text-center"
              :class="mobileDevice ? 'plan-sub-mobile' : 'plan-sub'"
            >
              of trip cost insured
            </div>
          </v-col><v-col
            class="d-flex justify-center"
            style="background-color: white;"
            cols="4"
          >
            <div
              class="d-flex justify-center text-center"
              :class="mobileDevice ? 'plan-sub-mobile' : 'plan-sub'"
            >
              of trip cost insured
            </div>
          </v-col>
        </v-col>
      </v-row>
      <!------------------------------------TRAVEL DELAY---------------------------------->
      <v-row class="d-flex justify-center">
        <v-col
          cols="12"
          sm="11"
          md="9"
          lg="9"
          class="d-flex justify-center neutral"
          style="border-top: 1px solid #e0e0e0; border-bottom: 1px solid #e0e0e0;"
        >
          <div :class="mobileDevice ? 'covd-title-mobile' : 'covd-title'">
            Travel Delay
          </div>
          <div class="help">
            <v-tooltip
              top
              color="secondary"
              max-width="250px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  color="primary"
                  dark
                  :class="mobileDevice ? 'mr-n4' : ''"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-help-circle
                </v-icon>
              </template>
              <span>Reimburses you for reasonable out of pocket expenses incurred,
                such as accomodations, meals and local transportation if you are
                delayed during your trip due to a covered reason. The delay must
                be: Standard- 10 hours or more Preferred- 8 hours or more
                Premium- 6 hours or more
              </span>
            </v-tooltip>
          </div>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center">
        <v-col
          cols="12"
          sm="11"
          md="9"
          lg="9"
          class="d-inline-flex pa-0"
        >
          <v-col
            class="d-flex justify-center border-right"
            style="background-color: white;"
            cols="4"
          >
            <div
              class="d-flex justify-center text-center secondary--text"
              :class="mobileDevice ? 'plan-title-mobile' : 'plan-title'"
            >
              $1000
            </div>
          </v-col><v-col
            class="d-flex justify-center border-right"
            style="background-color: white;"
            cols="4"
          >
            <div
              class="d-flex justify-center text-center primary--text"
              :class="mobileDevice ? 'plan-title-mobile' : 'plan-title'"
            >
              $1000
            </div>
          </v-col><v-col
            class="d-flex justify-center"
            style="background-color: white;"
            cols="4"
          >
            <div
              class="d-flex justify-center text-center travel--text"
              :class="mobileDevice ? 'plan-title-mobile' : 'plan-title'"
            >
              $1000
            </div>
          </v-col>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center mt-0">
        <v-col
          cols="12"
          sm="11"
          md="9"
          lg="9"
          class="d-inline-flex pa-0"
        >
          <v-col
            class="d-flex justify-center border-right"
            style="background-color: white;"
            cols="4"
          >
            <div
              class="d-flex justify-center text-center"
              :class="mobileDevice ? 'plan-sub-mobile' : 'plan-sub'"
            >
              Per person <br />
              ($300 per person daily limit applies)
            </div>
          </v-col><v-col
            class="d-flex justify-center border-right"
            style="background-color: white;"
            cols="4"
          >
            <div
              class="d-flex justify-center text-center"
              :class="mobileDevice ? 'plan-sub-mobile' : 'plan-sub'"
            >
              Per person <br />
              ($200 per person daily limit applies)
            </div>
          </v-col><v-col
            class="d-flex justify-center"
            style="background-color: white;"
            cols="4"
          >
            <div
              class="d-flex justify-center text-center"
              :class="mobileDevice ? 'plan-sub-mobile' : 'plan-sub'"
            >
              Per person <br />
              ($150 per person daily limit applies)
            </div>
          </v-col>
        </v-col>
      </v-row>
      <!------------------------------------BAGGAGE---------------------------------->
      <v-row class="d-flex justify-center">
        <v-col
          cols="12"
          sm="11"
          md="9"
          lg="9"
          class="d-flex justify-center neutral"
          style="border-top: 1px solid #e0e0e0; border-bottom: 1px solid #e0e0e0;"
        >
          <div :class="mobileDevice ? 'covd-title-mobile' : 'covd-title'">
            Baggage
          </div>
          <div class="help">
            <v-tooltip
              top
              color="secondary"
              max-width="250px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  color="primary"
                  dark
                  :class="mobileDevice ? 'mr-n4' : ''"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-help-circle
                </v-icon>
              </template>
              <span>Provides coverage for your baggage and covered personal effects
                if they are lost, damaged or stolen during your trip.</span>
            </v-tooltip>
          </div>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center">
        <v-col
          cols="12"
          sm="11"
          md="9"
          lg="9"
          class="d-inline-flex pa-0"
        >
          <v-col
            class="d-flex justify-center border-right"
            style="background-color: white;"
            cols="4"
          >
            <div
              class="d-flex justify-center text-center secondary--text"
              :class="mobileDevice ? 'plan-title-mobile' : 'plan-title'"
            >
              $2000
            </div>
          </v-col><v-col
            class="d-flex justify-center border-right"
            style="background-color: white;"
            cols="4"
          >
            <div
              class="d-flex justify-center text-center primary--text"
              :class="mobileDevice ? 'plan-title-mobile' : 'plan-title'"
            >
              $1500
            </div>
          </v-col><v-col
            class="d-flex justify-center"
            style="background-color: white;"
            cols="4"
          >
            <div
              class="d-flex justify-center text-center travel--text"
              :class="mobileDevice ? 'plan-title-mobile' : 'plan-title'"
            >
              $1000
            </div>
          </v-col>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center mt-0">
        <v-col
          cols="12"
          sm="11"
          md="9"
          lg="9"
          class="d-inline-flex pa-0"
        >
          <v-col
            class="d-flex justify-center border-right"
            style="background-color: white;"
            cols="4"
          >
            <div
              class="d-flex justify-center text-center"
              :class="mobileDevice ? 'plan-sub-mobile' : 'plan-sub'"
            >
              Per person
            </div>
          </v-col><v-col
            class="d-flex justify-center border-right"
            style="background-color: white;"
            cols="4"
          >
            <div
              class="d-flex justify-center text-center"
              :class="mobileDevice ? 'plan-sub-mobile' : 'plan-sub'"
            >
              Per person
            </div>
          </v-col><v-col
            class="d-flex justify-center"
            style="background-color: white;"
            cols="4"
          >
            <div
              class="d-flex justify-center text-center"
              :class="mobileDevice ? 'plan-sub-mobile' : 'plan-sub'"
            >
              Per person
            </div>
          </v-col>
        </v-col>
      </v-row>
      <!------------------------------------SPORTING EQUIPMENT---------------------------------->
      <v-row class="d-flex justify-center">
        <v-col
          cols="12"
          sm="11"
          md="9"
          lg="9"
          class="d-flex justify-center neutral"
          style="border-top: 1px solid #e0e0e0; border-bottom: 1px solid #e0e0e0;"
        >
          <div :class="mobileDevice ? 'covd-title-mobile' : 'covd-title'">
            Sporting Equipment
          </div>
          <div class="help">
            <v-tooltip
              top
              color="secondary"
              max-width="250px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  color="primary"
                  dark
                  :class="mobileDevice ? 'mr-n4' : ''"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-help-circle
                </v-icon>
              </template>
              <span>Provides coverage for loss, theft or damage to your sporting
                equipment during your covered trip.</span>
            </v-tooltip>
          </div>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center">
        <v-col
          cols="12"
          sm="11"
          md="9"
          lg="9"
          class="d-inline-flex pa-0"
        >
          <v-col
            class="d-flex justify-center border-right"
            style="background-color: white;"
            cols="4"
          >
            <div
              class="d-flex justify-center text-center secondary--text"
              :class="mobileDevice ? 'plan-title-mobile' : 'plan-title'"
            >
              $2000
            </div>
          </v-col><v-col
            class="d-flex justify-center border-right"
            style="background-color: white;"
            cols="4"
          >
            <div
              class="d-flex justify-center text-center primary--text"
              :class="mobileDevice ? 'plan-title-mobile' : 'plan-title'"
            >
              $1500
            </div>
          </v-col><v-col
            class="d-flex justify-center"
            style="background-color: white;"
            cols="4"
          >
            <div class="d-flex justify-center text-center">
              <v-icon
                x-large
                color="travel"
              >
                mdi-close
              </v-icon>
            </div>
          </v-col>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center mt-0">
        <v-col
          cols="12"
          sm="11"
          md="9"
          lg="9"
          class="d-inline-flex pa-0"
        >
          <v-col
            class="d-flex justify-center border-right"
            style="background-color: white;"
            cols="4"
          >
            <div
              class="d-flex justify-center text-center"
              :class="mobileDevice ? 'plan-sub-mobile' : 'plan-sub'"
            >
              Per person
            </div>
          </v-col><v-col
            class="d-flex justify-center border-right"
            style="background-color: white;"
            cols="4"
          >
            <div
              class="d-flex justify-center text-center"
              :class="mobileDevice ? 'plan-sub-mobile' : 'plan-sub'"
            >
              Per person
            </div>
          </v-col><v-col
            class="d-flex justify-center"
            style="background-color: white;"
            cols="4"
          >
          </v-col>
        </v-col>
      </v-row>
      <!------------------------------------BAGGAGE DELAY---------------------------------->
      <v-row class="d-flex justify-center">
        <v-col
          cols="12"
          sm="11"
          md="9"
          lg="9"
          class="d-flex justify-center neutral"
          style="border-top: 1px solid #e0e0e0; border-bottom: 1px solid #e0e0e0;"
        >
          <div :class="mobileDevice ? 'covd-title-mobile' : 'covd-title'">
            Baggage Delay
          </div>
          <div class="help">
            <v-tooltip
              top
              color="secondary"
              max-width="250px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  color="primary"
                  dark
                  :class="mobileDevice ? 'mr-n4' : ''"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-help-circle
                </v-icon>
              </template>
              <span>Provides reimbursement for the purchase of necessary items if
                your baggage is delayed during your trip. The delay must be:
                Standard- 24 hours or more Preferred- 18 hours or more Premium-
                12 hours or more</span>
            </v-tooltip>
          </div>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center">
        <v-col
          cols="12"
          sm="11"
          md="9"
          lg="9"
          class="d-inline-flex pa-0"
        >
          <v-col
            class="d-flex justify-center border-right"
            style="background-color: white;"
            cols="4"
          >
            <div
              class="d-flex justify-center text-center secondary--text"
              :class="mobileDevice ? 'plan-title-mobile' : 'plan-title'"
            >
              $500
            </div>
          </v-col><v-col
            class="d-flex justify-center border-right"
            style="background-color: white;"
            cols="4"
          >
            <div
              class="d-flex justify-center text-center primary--text"
              :class="mobileDevice ? 'plan-title-mobile' : 'plan-title'"
            >
              $300
            </div>
          </v-col><v-col
            class="d-flex justify-center"
            style="background-color: white;"
            cols="4"
          >
            <div
              class="d-flex justify-center text-center travel--text"
              :class="mobileDevice ? 'plan-title-mobile' : 'plan-title'"
            >
              $200
            </div>
          </v-col>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center mt-0">
        <v-col
          cols="12"
          sm="11"
          md="9"
          lg="9"
          class="d-inline-flex pa-0"
        >
          <v-col
            class="d-flex justify-center border-right"
            style="background-color: white;"
            cols="4"
          >
            <div
              class="d-flex justify-center text-center"
              :class="mobileDevice ? 'plan-sub-mobile' : 'plan-sub'"
            >
              Per person
            </div>
          </v-col><v-col
            class="d-flex justify-center border-right"
            style="background-color: white;"
            cols="4"
          >
            <div
              class="d-flex justify-center text-center"
              :class="mobileDevice ? 'plan-sub-mobile' : 'plan-sub'"
            >
              Per person
            </div>
          </v-col><v-col
            class="d-flex justify-center"
            style="background-color: white;"
            cols="4"
          >
            <div
              class="d-flex justify-center text-center"
              :class="mobileDevice ? 'plan-sub-mobile' : 'plan-sub'"
            >
              Per person
            </div>
          </v-col>
        </v-col>
      </v-row>
      <!------------------------------------SPORTING EQUIPMENT DELAY---------------------------------->
      <v-row class="d-flex justify-center">
        <v-col
          cols="12"
          sm="11"
          md="9"
          lg="9"
          class="d-flex justify-center neutral"
          style="border-top: 1px solid #e0e0e0; border-bottom: 1px solid #e0e0e0;"
        >
          <div :class="mobileDevice ? 'covd-title-mobile' : 'covd-title'">
            Sporting Equipment Delay
          </div>
          <div class="help">
            <v-tooltip
              top
              color="secondary"
              max-width="250px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  color="primary"
                  dark
                  :class="mobileDevice ? 'mr-n4' : ''"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-help-circle
                </v-icon>
              </template>
              <span>Provides reimbursement for the cost of Sporting Equipment
                rentals and expenses incurred to locate/track your delayed
                Sporting Equipment if your sporting equipment is delayed during
                your trip. The delay must be: Preferred- 18 hours or more,
                Premium- 12 hours or more
              </span>
            </v-tooltip>
          </div>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center">
        <v-col
          cols="12"
          sm="11"
          md="9"
          lg="9"
          class="d-inline-flex pa-0"
        >
          <v-col
            class="d-flex justify-center border-right"
            style="background-color: white;"
            cols="4"
          >
            <div
              class="d-flex justify-center text-center secondary--text"
              :class="mobileDevice ? 'plan-title-mobile' : 'plan-title'"
            >
              $500
            </div>
          </v-col><v-col
            class="d-flex justify-center border-right"
            style="background-color: white;"
            cols="4"
          >
            <div
              class="d-flex justify-center text-center primary--text"
              :class="mobileDevice ? 'plan-title-mobile' : 'plan-title'"
            >
              $300
            </div>
          </v-col><v-col
            class="d-flex justify-center"
            style="background-color: white;"
            cols="4"
          >
            <div class="d-flex justify-center text-center">
              <v-icon
                x-large
                color="travel"
              >
                mdi-close
              </v-icon>
            </div>
          </v-col>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center mt-0">
        <v-col
          cols="12"
          sm="11"
          md="9"
          lg="9"
          class="d-inline-flex pa-0"
        >
          <v-col
            class="d-flex justify-center border-right"
            style="background-color: white;"
            cols="4"
          >
            <div
              class="d-flex justify-center text-center"
              :class="mobileDevice ? 'plan-sub-mobile' : 'plan-sub'"
            >
              Per person
            </div>
          </v-col><v-col
            class="d-flex justify-center border-right"
            style="background-color: white;"
            cols="4"
          >
            <div
              class="d-flex justify-center text-center"
              :class="mobileDevice ? 'plan-sub-mobile' : 'plan-sub'"
            >
              Per person
            </div>
          </v-col><v-col
            class="d-flex justify-center"
            style="background-color: white;"
            cols="4"
          >
          </v-col>
        </v-col>
      </v-row>
      <!------------------------------------MISSED CONNECTION---------------------------------->
      <v-row class="d-flex justify-center">
        <v-col
          cols="12"
          sm="11"
          md="9"
          lg="9"
          class="d-flex justify-center neutral"
          style="border-top: 1px solid #e0e0e0; border-bottom: 1px solid #e0e0e0;"
        >
          <div :class="mobileDevice ? 'covd-title-mobile' : 'covd-title'">
            Missed Connection
          </div>
          <div class="help">
            <v-tooltip
              top
              color="secondary"
              max-width="250px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  color="primary"
                  dark
                  :class="mobileDevice ? 'mr-n4' : ''"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-help-circle
                </v-icon>
              </template>
              <span>Reimburses you for additional transportation, reasonable local
                accomodations, and unused land or water arrangements if you miss
                your cruise or tour departure because your flight was cancelled
                or delayed for 3 hours or more due to adverse weather.
                (Available if coverage is not available elsewhere)</span>
            </v-tooltip>
          </div>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center">
        <v-col
          cols="12"
          sm="11"
          md="9"
          lg="9"
          class="d-inline-flex pa-0"
        >
          <v-col
            class="d-flex justify-center border-right"
            style="background-color: white;"
            cols="4"
          >
            <div
              class="d-flex justify-center text-center secondary--text"
              :class="mobileDevice ? 'plan-title-mobile' : 'plan-title'"
            >
              $1000
            </div>
          </v-col><v-col
            class="d-flex justify-center border-right"
            style="background-color: white;"
            cols="4"
          >
            <div
              class="d-flex justify-center text-center primary--text"
              :class="mobileDevice ? 'plan-title-mobile' : 'plan-title'"
            >
              $750
            </div>
          </v-col><v-col
            class="d-flex justify-center"
            style="background-color: white;"
            cols="4"
          >
            <div
              class="d-flex justify-center text-center travel--text"
              :class="mobileDevice ? 'plan-title-mobile' : 'plan-title'"
            >
              $500
            </div>
          </v-col>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center mt-0">
        <v-col
          cols="12"
          sm="11"
          md="9"
          lg="9"
          class="d-inline-flex pa-0"
        >
          <v-col
            class="d-flex justify-center border-right"
            style="background-color: white;"
            cols="4"
          >
            <div
              class="d-flex justify-center text-center"
              :class="mobileDevice ? 'plan-sub-mobile' : 'plan-sub'"
            >
              Per person
            </div>
          </v-col><v-col
            class="d-flex justify-center border-right"
            style="background-color: white;"
            cols="4"
          >
            <div
              class="d-flex justify-center text-center"
              :class="mobileDevice ? 'plan-sub-mobile' : 'plan-sub'"
            >
              Per person
            </div>
          </v-col><v-col
            class="d-flex justify-center"
            style="background-color: white;"
            cols="4"
          >
            <div
              class="d-flex justify-center text-center"
              :class="mobileDevice ? 'plan-sub-mobile' : 'plan-sub'"
            >
              Per person
            </div>
          </v-col>
        </v-col>
      </v-row>
      <!------------------------------------MEDICAL AND DENTAL---------------------------------->
      <v-row class="d-flex justify-center">
        <v-col
          cols="12"
          sm="11"
          md="9"
          lg="9"
          class="d-flex justify-center neutral"
          style="border-top: 1px solid #e0e0e0; border-bottom: 1px solid #e0e0e0;"
        >
          <div :class="mobileDevice ? 'covd-title-mobile' : 'covd-title'">
            Medical & Dental
          </div>
          <div class="help">
            <v-tooltip
              top
              color="secondary"
              max-width="250px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  color="primary"
                  dark
                  :class="mobileDevice ? 'mr-n4' : ''"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-help-circle
                </v-icon>
              </template>
              <span>Reimburses you for necessary medical, surgical and emergency
                dental care costs if you get sick or accidentally injured while
                on your covered trip. Coverage is in excess of your standard
                coverage, except where prohibited.</span>
            </v-tooltip>
          </div>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center">
        <v-col
          cols="12"
          sm="11"
          md="9"
          lg="9"
          class="d-inline-flex pa-0"
        >
          <v-col
            class="d-flex justify-center border-right"
            style="background-color: white;"
            cols="4"
          >
            <div
              class="d-flex justify-center text-center secondary--text"
              :class="mobileDevice ? 'plan-title-mobile' : 'plan-title'"
            >
              $250,000
            </div>
          </v-col><v-col
            class="d-flex justify-center border-right"
            style="background-color: white;"
            cols="4"
          >
            <div
              class="d-flex justify-center text-center primary--text"
              :class="mobileDevice ? 'plan-title-mobile' : 'plan-title'"
            >
              $150,000
            </div>
          </v-col><v-col
            class="d-flex justify-center"
            style="background-color: white;"
            cols="4"
          >
            <div
              class="d-flex justify-center text-center travel--text"
              :class="mobileDevice ? 'plan-title-mobile' : 'plan-title'"
            >
              $50,000
            </div>
          </v-col>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center mt-0">
        <v-col
          cols="12"
          sm="11"
          md="9"
          lg="9"
          class="d-inline-flex pa-0"
        >
          <v-col
            class="d-flex justify-center border-right"
            style="background-color: white;"
            cols="4"
          >
            <div
              class="d-flex justify-center text-center"
              :class="mobileDevice ? 'plan-sub-mobile' : 'plan-sub'"
            >
              Per person
            </div>
          </v-col><v-col
            class="d-flex justify-center border-right"
            style="background-color: white;"
            cols="4"
          >
            <div
              class="d-flex justify-center text-center"
              :class="mobileDevice ? 'plan-sub-mobile' : 'plan-sub'"
            >
              Per person
            </div>
          </v-col><v-col
            class="d-flex justify-center"
            style="background-color: white;"
            cols="4"
          >
            <div
              class="d-flex justify-center text-center"
              :class="mobileDevice ? 'plan-sub-mobile' : 'plan-sub'"
            >
              Per person
            </div>
          </v-col>
        </v-col>
      </v-row>
      <!------------------------------------Emergency Assistance & Transportation---------------------------------->
      <v-row class="d-flex justify-center">
        <v-col
          cols="12"
          sm="11"
          md="9"
          lg="9"
          class="d-flex justify-center neutral"
          style="border-top: 1px solid #e0e0e0; border-bottom: 1px solid #e0e0e0;"
        >
          <div :class="mobileDevice ? 'covd-title-mobile' : 'covd-title'">
            Emergency Assistance & <br />
            <br />
            Transportation
          </div>
          <div class="help">
            <v-tooltip
              top
              color="secondary"
              max-width="250px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  color="primary"
                  dark
                  :class="mobileDevice ? 'mr-n4' : ''"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-help-circle
                </v-icon>
              </template>
              <span>Provides coverage for transportation to the nearest suitable
                medical facility, help to return home if medically necessary and
                up to $10,000 in expenses for a companion to visit you if you
                are traveling alone and are hospitalized for more than 7
                days.</span>
            </v-tooltip>
          </div>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center">
        <v-col
          cols="12"
          sm="11"
          md="9"
          lg="9"
          class="d-inline-flex pa-0"
        >
          <v-col
            class="d-flex justify-center border-right"
            style="background-color: white;"
            cols="4"
          >
            <div
              class="d-flex justify-center text-center secondary--text"
              :class="mobileDevice ? 'plan-title-mobile' : 'plan-title'"
            >
              $1,000,000
            </div>
          </v-col><v-col
            class="d-flex justify-center border-right"
            style="background-color: white;"
            cols="4"
          >
            <div
              class="d-flex justify-center text-center primary--text"
              :class="mobileDevice ? 'plan-title-mobile' : 'plan-title'"
            >
              $500,000
            </div>
          </v-col><v-col
            class="d-flex justify-center"
            style="background-color: white;"
            cols="4"
          >
            <div
              class="d-flex justify-center text-center travel--text"
              :class="mobileDevice ? 'plan-title-mobile' : 'plan-title'"
            >
              $250,000
            </div>
          </v-col>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center mt-0">
        <v-col
          cols="12"
          sm="11"
          md="9"
          lg="9"
          class="d-inline-flex pa-0"
        >
          <v-col
            class="d-flex justify-center border-right"
            style="background-color: white;"
            cols="4"
          >
            <div
              class="d-flex justify-center text-center"
              :class="mobileDevice ? 'plan-sub-mobile' : 'plan-sub'"
            >
              Per person
            </div>
          </v-col><v-col
            class="d-flex justify-center border-right"
            style="background-color: white;"
            cols="4"
          >
            <div
              class="d-flex justify-center text-center"
              :class="mobileDevice ? 'plan-sub-mobile' : 'plan-sub'"
            >
              Per person
            </div>
          </v-col><v-col
            class="d-flex justify-center"
            style="background-color: white;"
            cols="4"
          >
            <div
              class="d-flex justify-center text-center"
              :class="mobileDevice ? 'plan-sub-mobile' : 'plan-sub'"
            >
              Per person
            </div>
          </v-col>
        </v-col>
      </v-row>
      <!------------------------------------Accidental Death And Dismemberment Air---------------------------------->
      <v-row class="d-flex justify-center">
        <v-col
          cols="12"
          sm="11"
          md="9"
          lg="9"
          class="d-flex justify-center neutral"
          style="border-top: 1px solid #e0e0e0; border-bottom: 1px solid #e0e0e0;"
        >
          <div :class="mobileDevice ? 'covd-title-mobile' : 'covd-title'">
            Accidental Death & Dismemberment <br />
            <br />
            Air Flight Accident
          </div>
          <div class="help">
            <v-tooltip
              top
              color="secondary"
              max-width="250px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  color="primary"
                  dark
                  :class="mobileDevice ? 'mr-n4' : ''"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-help-circle
                </v-icon>
              </template>
              <span>Provides coverage if you are injured by an Air Flight Accident
                during your trip and you suffer the loss of life or limb within
                365 days of the Air Flight Accident.</span>
            </v-tooltip>
          </div>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center">
        <v-col
          cols="12"
          sm="11"
          md="9"
          lg="9"
          class="d-inline-flex pa-0"
        >
          <v-col
            class="d-flex justify-center border-right"
            style="background-color: white;"
            cols="4"
          >
            <div
              class="d-flex justify-center text-center secondary--text"
              :class="mobileDevice ? 'plan-title-mobile' : 'plan-title'"
            >
              $100,000
            </div>
          </v-col><v-col
            class="d-flex justify-center border-right"
            style="background-color: white;"
            cols="4"
          >
            <div
              class="d-flex justify-center text-center primary--text"
              :class="mobileDevice ? 'plan-title-mobile' : 'plan-title'"
            >
              $75,000
            </div>
          </v-col><v-col
            class="d-flex justify-center"
            style="background-color: white;"
            cols="4"
          >
            <div
              class="d-flex justify-center text-center travel--text"
              :class="mobileDevice ? 'plan-title-mobile' : 'plan-title'"
            >
              $50,000
            </div>
          </v-col>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center mt-0">
        <v-col
          cols="12"
          sm="11"
          md="9"
          lg="9"
          class="d-inline-flex pa-0"
        >
          <v-col
            class="d-flex justify-center border-right"
            style="background-color: white;"
            cols="4"
          >
            <div
              class="d-flex justify-center text-center"
              :class="mobileDevice ? 'plan-sub-mobile' : 'plan-sub'"
            >
              Per person
            </div>
          </v-col><v-col
            class="d-flex justify-center border-right"
            style="background-color: white;"
            cols="4"
          >
            <div
              class="d-flex justify-center text-center"
              :class="mobileDevice ? 'plan-sub-mobile' : 'plan-sub'"
            >
              Per person
            </div>
          </v-col><v-col
            class="d-flex justify-center"
            style="background-color: white;"
            cols="4"
          >
            <div
              class="d-flex justify-center text-center"
              :class="mobileDevice ? 'plan-sub-mobile' : 'plan-sub'"
            >
              Per person
            </div>
          </v-col>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center">
        <v-col
          cols="12"
          sm="11"
          md="9"
          lg="9"
          class="d-inline-flex pa-0"
        >
          <v-col
            class="d-flex justify-center border-right"
            style="background-color: white;"
            cols="4"
          >
            <div
              class="d-flex justify-center text-center secondary--text"
              :class="mobileDevice ? 'plan-title-mobile' : 'plan-title'"
            >
              $200,000
            </div>
          </v-col><v-col
            class="d-flex justify-center border-right"
            style="background-color: white;"
            cols="4"
          >
            <div
              class="d-flex justify-center text-center primary--text"
              :class="mobileDevice ? 'plan-title-mobile' : 'plan-title'"
            >
              $150,000
            </div>
          </v-col><v-col
            class="d-flex justify-center"
            style="background-color: white;"
            cols="4"
          >
            <div
              class="d-flex justify-center text-center travel--text"
              :class="mobileDevice ? 'plan-title-mobile' : 'plan-title'"
            >
              $100,000
            </div>
          </v-col>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center mt-0">
        <v-col
          cols="12"
          sm="11"
          md="9"
          lg="9"
          class="d-inline-flex pa-0"
        >
          <v-col
            class="d-flex justify-center border-right"
            style="background-color: white;"
            cols="4"
          >
            <div
              class="d-flex justify-center text-center"
              :class="mobileDevice ? 'plan-sub-mobile' : 'plan-sub'"
            >
              Per plan
            </div>
          </v-col><v-col
            class="d-flex justify-center border-right"
            style="background-color: white;"
            cols="4"
          >
            <div
              class="d-flex justify-center text-center"
              :class="mobileDevice ? 'plan-sub-mobile' : 'plan-sub'"
            >
              Per plan
            </div>
          </v-col><v-col
            class="d-flex justify-center"
            style="background-color: white;"
            cols="4"
          >
            <div
              class="d-flex justify-center text-center"
              :class="mobileDevice ? 'plan-sub-mobile' : 'plan-sub'"
            >
              Per plan
            </div>
          </v-col>
        </v-col>
      </v-row>
      <!------------------------------------Accidental Death And Dismemberment Travel---------------------------------->
      <v-row class="d-flex justify-center">
        <v-col
          cols="12"
          sm="11"
          md="9"
          lg="9"
          class="d-flex justify-center neutral"
          style="border-top: 1px solid #e0e0e0; border-bottom: 1px solid #e0e0e0;"
        >
          <div :class="mobileDevice ? 'covd-title-mobile' : 'covd-title'">
            Accidental Death & Dismemberment <br />
            <br />
            Travel Accident
          </div>
          <div class="help">
            <v-tooltip
              top
              color="secondary"
              max-width="250px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  color="primary"
                  dark
                  :class="mobileDevice ? 'mr-n4' : ''"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-help-circle
                </v-icon>
              </template>
              <span>Provides coverage if you are injured by an accident during your
                trip and you suffer the loss of life or limb within 365 days of
                the accident.</span>
            </v-tooltip>
          </div>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center">
        <v-col
          cols="12"
          sm="11"
          md="9"
          lg="9"
          class="d-inline-flex pa-0"
        >
          <v-col
            class="d-flex justify-center border-right"
            style="background-color: white;"
            cols="4"
          >
            <div
              class="d-flex justify-center text-center secondary--text"
              :class="mobileDevice ? 'plan-title-mobile' : 'plan-title'"
            >
              $50,000
            </div>
          </v-col><v-col
            class="d-flex justify-center border-right"
            style="background-color: white;"
            cols="4"
          >
            <div
              class="d-flex justify-center text-center primary--text"
              :class="mobileDevice ? 'plan-title-mobile' : 'plan-title'"
            >
              $25,000
            </div>
          </v-col><v-col
            class="d-flex justify-center"
            style="background-color: white;"
            cols="4"
          >
            <div class="d-flex justify-center text-center">
              <v-icon
                x-large
                color="travel"
              >
                mdi-close
              </v-icon>
            </div>
          </v-col>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center mt-0">
        <v-col
          cols="12"
          sm="11"
          md="9"
          lg="9"
          class="d-inline-flex pa-0"
        >
          <v-col
            class="d-flex justify-center border-right"
            style="background-color: white;"
            cols="4"
          >
            <div
              class="d-flex justify-center text-center"
              :class="mobileDevice ? 'plan-sub-mobile' : 'plan-sub'"
            >
              Per person
            </div>
          </v-col><v-col
            class="d-flex justify-center border-right"
            style="background-color: white;"
            cols="4"
          >
            <div
              class="d-flex justify-center text-center"
              :class="mobileDevice ? 'plan-sub-mobile' : 'plan-sub'"
            >
              Per person
            </div>
          </v-col><v-col
            class="d-flex justify-center"
            style="background-color: white;"
            cols="4"
          >
          </v-col>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center">
        <v-col
          cols="12"
          sm="11"
          md="9"
          lg="9"
          class="d-inline-flex pa-0"
        >
          <v-col
            class="d-flex justify-center border-right"
            style="background-color: white;"
            cols="4"
          >
            <div
              class="d-flex justify-center text-center secondary--text"
              :class="mobileDevice ? 'plan-title-mobile' : 'plan-title'"
            >
              $100,000
            </div>
          </v-col><v-col
            class="d-flex justify-center border-right"
            style="background-color: white;"
            cols="4"
          >
            <div
              class="d-flex justify-center text-center primary--text"
              :class="mobileDevice ? 'plan-title-mobile' : 'plan-title'"
            >
              $50,000
            </div>
          </v-col><v-col
            class="d-flex justify-center"
            style="background-color: white;"
            cols="4"
          >
            <div class="d-flex justify-center text-center">
              <v-icon
                x-large
                color="travel"
              >
                mdi-close
              </v-icon>
            </div>
          </v-col>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center mt-0">
        <v-col
          cols="12"
          sm="11"
          md="9"
          lg="9"
          class="d-inline-flex pa-0"
        >
          <v-col
            class="d-flex justify-center border-right"
            style="background-color: white;"
            cols="4"
          >
            <div
              class="d-flex justify-center text-center"
              :class="mobileDevice ? 'plan-sub-mobile' : 'plan-sub'"
            >
              Per plan
            </div>
          </v-col><v-col
            class="d-flex justify-center border-right"
            style="background-color: white;"
            cols="4"
          >
            <div
              class="d-flex justify-center text-center"
              :class="mobileDevice ? 'plan-sub-mobile' : 'plan-sub'"
            >
              Per plan
            </div>
          </v-col><v-col
            class="d-flex justify-center"
            style="background-color: white;"
            cols="4"
          >
          </v-col>
        </v-col>
      </v-row>
      <!----------------------------------------Features---------------------------------->
      <v-row justify="center">
        <v-col
          class="d-flex justify-center font-weight-bold mb-0 pb-0 mt-16"
          :class="mobileDevice ? 'covg-title-mobile' : 'covg-title'"
        >
          Features
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center align-center mt-12 mb-0 pb-0">
        <v-col
          cols="12"
          sm="11"
          md="9"
          lg="9"
          class="d-inline-flex pa-0"
        >
          <v-col
            cols="4"
            class="premium d-flex justify-center border-top-left"
            :class="mobileDevice ? 'plan-type-mobile' : 'plan-type'"
          >
            Premium
          </v-col>
          <v-col
            cols="4"
            class="preferred d-flex justify-center"
            :class="mobileDevice ? 'plan-type-mobile' : 'plan-type'"
          >
            Preferred
          </v-col>
          <v-col
            cols="4"
            class="standard d-flex justify-center"
            :class="mobileDevice ? 'plan-type-mobile' : 'plan-type'"
          >
            Standard
          </v-col>
        </v-col>
      </v-row>
      <!----------------------------------------Coverage for Prexisting Medical Conditions---------------------------------->
      <v-row class="d-flex justify-center my-0">
        <v-col
          cols="12"
          sm="11"
          md="9"
          lg="9"
          class="d-flex justify-center neutral text-center"
          :class="mobileDevice ? 'covd-title-mobile' : 'covd-title'"
          style="border: 1px solid #e0e0e0;"
        >
          <div>
            Coverage for Pre-Existing <br />
            <br />
            Medical Conditions
          </div>
          <div class="help">
            <v-tooltip
              top
              color="secondary"
              max-width="250px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  color="primary"
                  dark
                  :class="mobileDevice ? 'mr-n4' : ''"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-help-circle
                </v-icon>
              </template>
              <span>Pre-Existing Conditions can be accepted when the Premium plan
                is purchased prior to or within 24 hours of final trip payment
                if other requirements are met. Please see a sample of
                Description of Coverage or Policy for full details.</span>
            </v-tooltip>
          </div>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center mt-0">
        <v-col
          cols="12"
          sm="11"
          md="9"
          lg="9"
          class="d-inline-flex pa-0"
        >
          <v-col
            class="d-flex justify-center border-right"
            style="background-color: white;"
            cols="4"
          >
            <div
              class="d-flex justify-center text-center secondary--text"
              :class="mobileDevice ? 'plan-title-mobile' : 'plan-title'"
            >
              Included
            </div>
          </v-col><v-col
            class="d-flex justify-center border-right"
            style="background-color: white;"
            cols="4"
          >
            <v-icon
              color="primary"
              x-large
            >
              mdi-close
            </v-icon>
          </v-col><v-col
            class="d-flex justify-center"
            style="background-color: white;"
            cols="4"
          >
            <v-icon
              color="travel"
              x-large
            >
              mdi-close
            </v-icon>
          </v-col>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center mt-0">
        <v-col
          cols="12"
          sm="11"
          md="9"
          lg="9"
          class="d-inline-flex pa-0"
        >
          <v-col
            class="d-flex justify-center border-right"
            style="background-color: white;"
            cols="4"
          >
            <div class="d-flex justify-center text-center">
              Requirements Apply
            </div>
          </v-col><v-col
            class="d-flex justify-center border-right"
            style="background-color: white;"
            cols="4"
          >
          </v-col><v-col
            class="d-flex justify-center"
            style="background-color: white;"
            cols="4"
          >
          </v-col>
        </v-col>
      </v-row>
      <!----------------------------------------Coverage for Financial Insolvency---------------------------------->
      <v-row class="d-flex justify-center">
        <v-col
          cols="12"
          sm="11"
          md="9"
          lg="9"
          class="d-flex justify-center neutral"
          style="border-top: 1px solid #e0e0e0; border-bottom: 1px solid #e0e0e0;"
        >
          <div :class="mobileDevice ? 'covd-title-mobile' : 'covd-title'">
            Coverage for Financial Insolvency
          </div>
          <div class="help">
            <v-tooltip
              top
              color="secondary"
              max-width="250px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  color="primary"
                  dark
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-help-circle
                </v-icon>
              </template>
              <span>Financial Insolvency of a travel supplier is a covered reason
                for Trip Cancellation and Trip Interruption provided you
                purchased the Preferred or Premium plan prior to or within 24
                hours of final trip payment. Please see a sample Description of
                Coverage or Policy for full details.</span>
            </v-tooltip>
          </div>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center">
        <v-col
          cols="12"
          sm="11"
          md="9"
          lg="9"
          class="d-inline-flex pa-0"
        >
          <v-col
            class="d-flex justify-center border-right"
            style="background-color: white;"
            cols="4"
          >
            <div
              class="d-flex justify-center text-center secondary--text"
              :class="mobileDevice ? 'plan-title-mobile' : 'plan-title'"
            >
              Included
            </div>
          </v-col><v-col
            class="d-flex justify-center border-right"
            style="background-color: white;"
            cols="4"
          >
            <div
              class="d-flex justify-center text-center primary--text"
              :class="mobileDevice ? 'plan-title-mobile' : 'plan-title'"
            >
              Included
            </div>
          </v-col><v-col
            class="d-flex justify-center"
            style="background-color: white;"
            cols="4"
          >
            <v-icon
              color="travel"
              x-large
            >
              mdi-close
            </v-icon>
          </v-col>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center mt-0">
        <v-col
          cols="12"
          sm="11"
          md="9"
          lg="9"
          class="d-inline-flex pa-0"
        >
          <v-col
            class="d-flex justify-center border-right"
            style="background-color: white;"
            cols="4"
          >
            <div class="d-flex justify-center text-center">
              Requirements Apply
            </div>
          </v-col><v-col
            class="d-flex justify-center border-right"
            style="background-color: white;"
            cols="4"
          >
            <div class="d-flex justify-center text-center">
              Requirements Apply
            </div>
          </v-col><v-col
            class="d-flex justify-center"
            style="background-color: white;"
            cols="4"
          >
          </v-col>
        </v-col>
      </v-row>
      <!----------------------------------------Travel Services---------------------------------->
      <v-row justify="center">
        <v-col
          class="d-flex justify-center font-weight-bold mb-0 pb-0 mt-16"
          :class="mobileDevice ? 'covg-title-mobile' : 'covg-title'"
        >
          Travel Services
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col class="d-flex justify-center mt-0 pt-0">
          Provided by Generali Global Assistance's designated provider
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center align-center mt-12 mb-0 pb-0">
        <v-col
          cols="12"
          sm="11"
          md="9"
          lg="9"
          class="d-inline-flex pa-0"
        >
          <v-col
            cols="4"
            class="premium d-flex justify-center border-top-left"
            :class="mobileDevice ? 'plan-type-mobile' : 'plan-type'"
          >
            Premium
          </v-col>
          <v-col
            cols="4"
            class="preferred d-flex justify-center"
            :class="mobileDevice ? 'plan-type-mobile' : 'plan-type'"
          >
            Preferred
          </v-col>
          <v-col
            cols="4"
            class="standard d-flex justify-center"
            :class="mobileDevice ? 'plan-type-mobile' : 'plan-type'"
          >
            Standard
          </v-col>
        </v-col>
      </v-row>
      <!----------------------------------------24-Hour Travel Assistance Services---------------------------------->
      <v-row class="d-flex justify-center my-0">
        <v-col
          cols="12"
          sm="11"
          md="9"
          lg="9"
          class="d-flex justify-center neutral text-center"
          :class="mobileDevice ? 'covd-title-mobile' : 'covd-title'"
          style="border: 1px solid #e0e0e0;"
        >
          <div>24-Hour Travel Assistance Services</div>
          <div class="help">
            <v-tooltip
              top
              color="secondary"
              max-width="250px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  color="primary"
                  dark
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-help-circle
                </v-icon>
              </template>
              <span>On-the-spot and immediate assistance for unexpected problems
                during your trip. The hotline operates 24/7 for help anytime
                while traveling.</span>
            </v-tooltip>
          </div>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center mt-0">
        <v-col
          cols="12"
          sm="11"
          md="9"
          lg="9"
          class="d-inline-flex pa-0"
        >
          <v-col
            class="d-flex justify-center border-right py-6"
            style="background-color: white;"
            cols="4"
          >
            <v-icon
              color="secondary"
              x-large
            >
              mdi-check-bold
            </v-icon>
          </v-col><v-col
            class="d-flex justify-center border-right py-6"
            style="background-color: white;"
            cols="4"
          >
            <v-icon
              color="primary"
              x-large
            >
              mdi-check-bold
            </v-icon>
          </v-col><v-col
            class="d-flex justify-center py-6"
            style="background-color: white;"
            cols="4"
          >
            <v-icon
              color="travel"
              x-large
            >
              mdi-check-bold
            </v-icon>
          </v-col>
        </v-col>
      </v-row>
      <!----------------------------------------Telemedicine---------------------------------->
      <v-row class="d-flex justify-center">
        <v-col
          cols="12"
          sm="11"
          md="9"
          lg="9"
          class="d-flex justify-center neutral"
          style="border-top: 1px solid #e0e0e0; border-bottom: 1px solid #e0e0e0;"
        >
          <div :class="mobileDevice ? 'covd-title-mobile' : 'covd-title'">
            Telemedicine
          </div>
          <div class="help">
            <v-tooltip
              top
              color="secondary"
              max-width="250px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  color="primary"
                  dark
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-help-circle
                </v-icon>
              </template>
              <span>If you're not feeling well on your trip you can consult with a
                doctor via phone or video, anywhere and anytime.</span>
            </v-tooltip>
          </div>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center">
        <v-col
          cols="12"
          sm="11"
          md="9"
          lg="9"
          class="d-inline-flex pa-0"
        >
          <v-col
            class="d-flex justify-center border-right py-6"
            style="background-color: white;"
            cols="4"
          >
            <v-icon
              color="secondary"
              x-large
            >
              mdi-check-bold
            </v-icon>
          </v-col><v-col
            class="d-flex justify-center border-right py-6"
            style="background-color: white;"
            cols="4"
          >
            <v-icon
              color="primary"
              x-large
            >
              mdi-check-bold
            </v-icon>
          </v-col><v-col
            class="d-flex justify-center py-6"
            style="background-color: white;"
            cols="4"
          >
            <v-icon
              color="travel"
              x-large
            >
              mdi-check-bold
            </v-icon>
          </v-col>
        </v-col>
      </v-row>
      <!----------------------------------------No Out-of-Pocket Medical---------------------------------->
      <v-row class="d-flex justify-center">
        <v-col
          cols="12"
          sm="11"
          md="9"
          lg="9"
          class="d-flex justify-center neutral"
          style="border-top: 1px solid #e0e0e0; border-bottom: 1px solid #e0e0e0;"
        >
          <div :class="mobileDevice ? 'covd-title-mobile' : 'covd-title'">
            No Out-of-Pocket Medical
          </div>
          <div class="help">
            <v-tooltip
              top
              color="secondary"
              max-width="250px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  color="primary"
                  dark
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-help-circle
                </v-icon>
              </template>
              <span>A service that can arrange your appointment and pay up front
                for acute treatments up to $1,000 if you get sick or injured
                while traveling.</span>
            </v-tooltip>
          </div>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center">
        <v-col
          cols="12"
          sm="11"
          md="9"
          lg="9"
          class="d-inline-flex pa-0"
        >
          <v-col
            class="d-flex justify-center border-right py-6"
            style="background-color: white;"
            cols="4"
          >
            <v-icon
              color="secondary"
              x-large
            >
              mdi-check-bold
            </v-icon>
          </v-col><v-col
            class="d-flex justify-center border-right py-6"
            style="background-color: white;"
            cols="4"
          >
            <v-icon
              color="primary"
              x-large
            >
              mdi-check-bold
            </v-icon>
          </v-col><v-col
            class="d-flex justify-center py-6"
            style="background-color: white;"
            cols="4"
          >
            <v-icon
              color="travel"
              x-large
            >
              mdi-check-bold
            </v-icon>
          </v-col>
        </v-col>
      </v-row>
      <!----------------------------------------Concierge Services---------------------------------->
      <v-row class="d-flex justify-center">
        <v-col
          cols="12"
          sm="11"
          md="9"
          lg="9"
          class="d-flex justify-center neutral"
          style="border-top: 1px solid #e0e0e0; border-bottom: 1px solid #e0e0e0;"
        >
          <div :class="mobileDevice ? 'covd-title-mobile' : 'covd-title'">
            Concierge Services
          </div>
          <div class="help">
            <v-tooltip
              top
              color="secondary"
              max-width="250px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  color="primary"
                  dark
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-help-circle
                </v-icon>
              </template>
              <span>Provides pre-trip assistance, help making airline, hotel,
                rental car and restaurant reservations, event ticketing
                services, help scheduling golf tee times and more.</span>
            </v-tooltip>
          </div>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center">
        <v-col
          cols="12"
          sm="11"
          md="9"
          lg="9"
          class="d-inline-flex pa-0"
        >
          <v-col
            class="d-flex justify-center border-right py-6"
            style="background-color: white;"
            cols="4"
          >
            <v-icon
              color="secondary"
              x-large
            >
              mdi-check-bold
            </v-icon>
          </v-col><v-col
            class="d-flex justify-center border-right py-6"
            style="background-color: white;"
            cols="4"
          >
            <v-icon
              color="primary"
              x-large
            >
              mdi-check-bold
            </v-icon>
          </v-col><v-col
            class="d-flex justify-center py-6"
            style="background-color: white;"
            cols="4"
          >
            <v-icon
              color="travel"
              x-large
            >
              mdi-check-bold
            </v-icon>
          </v-col>
        </v-col>
      </v-row>
      <!----------------------------------------Identity Theft Resolution Services---------------------------------->
      <v-row class="d-flex justify-center">
        <v-col
          cols="12"
          sm="11"
          md="9"
          lg="9"
          class="d-flex justify-center neutral"
          style="border-top: 1px solid #e0e0e0; border-bottom: 1px solid #e0e0e0;"
        >
          <div :class="mobileDevice ? 'covd-title-mobile' : 'covd-title'">
            Identity Theft Resolution Services
          </div>
          <div class="help">
            <v-tooltip
              top
              color="secondary"
              max-width="250px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  color="primary"
                  dark
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-help-circle
                </v-icon>
              </template>
              <span>A service that provides assistance when your identity has been
                compromised while traveling on your vacation. This service is
                automatically included for a full 180 days starting on your
                scheduled departure date. ID Theft Resolution does not include,
                and shall not assist you for thefts involving non-U.S. bank
                accounts.</span>
            </v-tooltip>
          </div>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center">
        <v-col
          cols="12"
          sm="11"
          md="9"
          lg="9"
          class="d-inline-flex pa-0"
        >
          <v-col
            class="d-flex justify-center border-right py-6"
            style="background-color: white;"
            cols="4"
          >
            <v-icon
              color="secondary"
              x-large
            >
              mdi-check-bold
            </v-icon>
          </v-col><v-col
            class="d-flex justify-center border-right py-6"
            style="background-color: white;"
            cols="4"
          >
            <v-icon
              color="primary"
              x-large
            >
              mdi-check-bold
            </v-icon>
          </v-col><v-col
            class="d-flex justify-center py-6"
            style="background-color: white;"
            cols="4"
          >
            <v-icon
              color="travel"
              x-large
            >
              mdi-check-bold
            </v-icon>
          </v-col>
        </v-col>
      </v-row>
      <!--------------------------------------Baseline Quote---------------------------------->
      <v-row class="d-flex justify-center">
        <v-col
          cols="9"
          class="d-flex justify-center transparent"
        >
          Your Baseline Quote
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center mb-4">
        <v-col
          cols="11"
          sm="11"
          md="9"
          lg="9"
          class="d-inline-flex pa-0"
        >
          <v-col class="d-flex justify-center">
            <div
              class="text-center font-weight-bold secondary--text"
              :class="mobileDevice ? 'plan-title-mobile' : 'plan-title'"
            >
              ${{ premPrice }}
            </div>
          </v-col><v-col class="d-flex justify-center">
            <div
              class="text-center font-weight-bold primary--text"
              :class="mobileDevice ? 'plan-title-mobile' : 'plan-title'"
            >
              ${{ prefPrice }}
            </div>
          </v-col><v-col class="d-flex justify-center">
            <div
              class="text-center font-weight-bold travel--text"
              :class="mobileDevice ? 'plan-title-mobile' : 'plan-title'"
            >
              ${{ stndPrice }}
            </div>
          </v-col>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center">
        <v-col
          cols="12"
          sm="11"
          md="9"
          lg="9"
        >
          <v-divider></v-divider>
        </v-col>
      </v-row>
      <!--------------------------------------Optional Coverages-------------------------------->
      <v-row>
        <v-col
          class="d-flex justify-center font-weight-bold mb-0 pb-0 mt-16"
          :class="mobileDevice ? 'covg-title-mobile' : 'covg-title'"
        >
          Optional Coverages
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center align-center mt-12 mb-0 pb-0">
        <v-col
          cols="12"
          sm="11"
          md="9"
          lg="9"
          class="d-inline-flex pa-0"
        >
          <v-col
            cols="4"
            class="premium d-flex justify-center border-top-left"
            :class="mobileDevice ? 'plan-type-mobile' : 'plan-type'"
          >
            Premium
          </v-col>
          <v-col
            cols="4"
            class="preferred d-flex justify-center"
            :class="mobileDevice ? 'plan-type-mobile' : 'plan-type'"
          >
            Preferred
          </v-col>
          <v-col
            cols="4"
            class="standard d-flex justify-center"
            :class="mobileDevice ? 'plan-type-mobile' : 'plan-type'"
          >
            Standard
          </v-col>
        </v-col>
      </v-row>
      <!-----------------------------------Rental Car Damage------------------------------->
      <div v-if="travelInfo.living !== 'Texas'">
        <v-row class="d-flex justify-center">
          <v-col
            cols="12"
            sm="11"
            md="9"
            lg="9"
            class="d-flex justify-center neutral"
            style="border-top: 1px solid #e0e0e0; border-bottom: 1px solid #e0e0e0;"
          >
            <div :class="mobileDevice ? 'covd-title-mobile' : 'covd-title'">
              Rental Car Damage
            </div>
            <div class="help">
              <v-tooltip
                top
                color="secondary"
                max-width="250px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="primary"
                    dark
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-help-circle
                  </v-icon>
                </template>
                <span>Provides primary coverage if your rental car is damaged due
                  to collision, fire, flood, theft, vandalism, windstorm or
                  hail.</span>
              </v-tooltip>
            </div>
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center">
          <v-col
            cols="12"
            sm="11"
            md="9"
            lg="9"
            class="d-flex justify-center neutral text-h5"
          >
            Not Available to Residents of Texas
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center">
          <v-col
            cols="12"
            sm="11"
            md="9"
            lg="9"
            class="d-inline-flex pa-0"
          >
            <v-col
              class="d-flex justify-center border-right"
              style="background-color: white;"
              cols="4"
            >
              <div
                class="d-flex justify-center text-center secondary--text"
                :class="mobileDevice ? 'plan-title-mobile' : 'plan-title'"
              >
                $25,000
              </div>
            </v-col>
            <v-col
              class="d-flex justify-center border-right"
              style="background-color: white;"
              cols="4"
            >
              <div
                class="d-flex justify-center text-center primary--text"
                :class="mobileDevice ? 'plan-title-mobile' : 'plan-title'"
              >
                $25,000
              </div>
            </v-col>
            <v-col
              class="d-flex justify-center border-right"
              style="background-color: white;"
              cols="4"
            >
              <div
                class="d-flex justify-center text-center travel--text"
                :class="mobileDevice ? 'plan-title-mobile' : 'plan-title'"
              >
                $25,000
              </div>
            </v-col>
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center">
          <v-col
            cols="12"
            sm="11"
            md="9"
            lg="9"
            class="d-inline-flex pa-0"
          >
            <v-col
              class="d-flex justify-center border-right"
              style="background-color: white;"
              cols="4"
            >
              <div class="d-flex justify-center text-center">
                Per person / <br />
                Per plan
              </div>
            </v-col><v-col
              class="d-flex justify-center border-right"
              style="background-color: white;"
              cols="4"
            >
              <div class="d-flex justify-center text-center">
                Per person / <br />
                Per plan
              </div>
            </v-col><v-col
              class="d-flex justify-center border-right"
              style="background-color: white;"
              cols="4"
            >
              <div class="d-flex justify-center text-center">
                Per person / <br />
                Per plan
              </div>
            </v-col>
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center">
          <v-col
            cols="12"
            sm="11"
            md="9"
            lg="9"
            class="d-inline-flex pa-0"
          >
            <v-col
              class="d-flex justify-center border-right"
              style="background-color: white;"
              cols="4"
            >
              <div
                class="d-flex justify-center text-center secondary--text"
                :class="mobileDevice ? 'plan-title-mobile' : 'plan-title'"
              >
                Included
              </div>
            </v-col>
            <v-col
              class="d-flex justify-center border-right font-weight-bold"
              style="background-color: white;"
              cols="4"
            >
              Available Coverage Dates
              <div class="ml-2">
                <v-tooltip
                  top
                  color="secondary"
                  max-width="250px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      color="primary"
                      dark
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-help-circle
                    </v-icon>
                  </template>
                  <span>Enter the dates you want the rental car damage
                    coverage.</span>
                </v-tooltip>
              </div>
            </v-col>
            <v-col
              class="d-flex justify-center border-right font-weight-bold"
              style="background-color: white;"
              cols="4"
            >
              Available Coverage Dates
              <div class="ml-2">
                <v-tooltip
                  top
                  color="secondary"
                  max-width="250px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      color="primary"
                      dark
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-help-circle
                    </v-icon>
                  </template>
                  <span>Enter the dates you want the rental car damage
                    coverage.</span>
                </v-tooltip>
              </div>
            </v-col>
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center">
          <v-col
            cols="12"
            sm="11"
            md="9"
            lg="9"
            class="d-inline-flex pa-0"
          >
            <v-col
              class="d-flex justify-center border-right"
              style="background-color: white;"
              cols="4"
            >
            </v-col>
            <v-col
              class="d-flex justify-center border-right"
              style="background-color: white;"
              cols="4"
            >
              <v-dialog
                ref="dialog1"
                v-model="modal1"
                :return-value.sync="startDatePref"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Start Date"
                  >
                    <v-text-field
                      v-model="startDatePref"
                      outlined
                      label="From"
                      :error-messages="errors"
                      v-bind="attrs"
                      v-on="on"
                      @click="
                        clickedFieldGlobal(
                          'startDate',
                          40,
                          'American Travel Survey v1'
                        )
                      "
                    ></v-text-field>
                  </validation-provider>
                </template>
                <v-date-picker
                  v-model="startDatePref"
                  scrollable
                  :min="travelInfo.departdate"
                  :max="travelInfo.returndate"
                  @change="setEndDatePref"
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    color="primary"
                    @click="
                      modal1 = false;
                      clickedFieldGlobal(
                        'rentalDamagePrefStartCancel',
                        41,
                        'American Travel Survey v1'
                      );
                    "
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="
                      $refs.dialog1.save(startDatePref);
                      rentalDamagePref();
                      clickedFieldGlobal(
                        'rentalDamagePrefStartOk',
                        42,
                        'American Travel Survey v1'
                      );
                    "
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-dialog>
            </v-col>
            <v-col
              class="d-flex justify-center border-right"
              style="background-color: white;"
              cols="4"
            >
              <v-dialog
                ref="dialog2"
                v-model="modal2"
                :return-value.sync="startDateStd"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <validation-provider
                    v-slot="{ errors }"
                    name="End Date"
                  >
                    <v-text-field
                      v-model="startDateStd"
                      outlined
                      label="From"
                      :error-messages="errors"
                      v-bind="attrs"
                      v-on="on"
                      @click="
                        clickedFieldGlobal(
                          'endDate',
                          43,
                          'American Travel Survey v1'
                        )
                      "
                    ></v-text-field>
                  </validation-provider>
                </template>
                <v-date-picker
                  v-model="startDateStd"
                  scrollable
                  :min="travelInfo.departdate"
                  :max="travelInfo.returndate"
                  @change="setEndDateStd"
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    color="primary"
                    @click="
                      modal2 = false;
                      clickedFieldGlobal(
                        'rentalDamageStdStartCancel',
                        44,
                        'American Travel Survey v1'
                      );
                    "
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="
                      $refs.dialog2.save(startDateStd);
                      rentalDamageStd();
                      clickedFieldGlobal(
                        'rentalDamageStdStartOk',
                        45,
                        'American Travel Survey v1'
                      );
                    "
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-dialog>
            </v-col>
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center">
          <v-col
            cols="12"
            sm="11"
            md="9"
            lg="9"
            class="d-inline-flex pa-0"
          >
            <v-col
              class="d-flex justify-center border-right"
              style="background-color: white;"
              cols="4"
            >
            </v-col>
            <v-col
              class="d-flex justify-center border-right"
              style="background-color: white;"
              cols="4"
            >
              <v-dialog
                ref="dialog3"
                v-model="modal3"
                :return-value.sync="endDatePref"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Start Date"
                  >
                    <v-text-field
                      v-model="endDatePref"
                      outlined
                      label="To"
                      :error-messages="errors"
                      v-bind="attrs"
                      v-on="on"
                      @click="
                        clickedFieldGlobal(
                          'startDate',
                          46,
                          'American Travel Survey v1'
                        )
                      "
                    ></v-text-field>
                  </validation-provider>
                </template>
                <v-date-picker
                  v-model="endDatePref"
                  scrollable
                  :min="startDatePref"
                  :max="travelInfo.returndate"
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    color="primary"
                    @click="
                      modal3 = false;
                      clickedFieldGlobal(
                        'rentalDamagePrefEndCancel',
                        47,
                        'American Travel Survey v1'
                      );
                    "
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="
                      $refs.dialog3.save(endDatePref);
                      rentalDamagePref();
                      clickedFieldGlobal(
                        'rentalDamagePrefEndOk',
                        48,
                        'American Travel Survey v1'
                      );
                    "
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-dialog>
            </v-col>
            <v-col
              class="d-flex justify-center border-right"
              style="background-color: white;"
              cols="4"
            >
              <v-dialog
                ref="dialog4"
                v-model="modal4"
                :return-value.sync="endDateStd"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <validation-provider
                    v-slot="{ errors }"
                    name="End Date"
                  >
                    <v-text-field
                      v-model="endDateStd"
                      outlined
                      label="To"
                      :error-messages="errors"
                      v-bind="attrs"
                      v-on="on"
                      @click="
                        clickedFieldGlobal(
                          'endDate',
                          49,
                          'American Travel Survey v1'
                        )
                      "
                    ></v-text-field>
                  </validation-provider>
                </template>
                <v-date-picker
                  v-model="endDateStd"
                  scrollable
                  :min="startDateStd"
                  :max="travelInfo.returndate"
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    color="primary"
                    @click="
                      modal4 = false;
                      clickedFieldGlobal(
                        'rentalDamageStdEndCancel',
                        50,
                        'American Travel Survey v1'
                      );
                    "
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="
                      $refs.dialog4.save(endDateStd);
                      rentalDamageStd();
                      clickedFieldGlobal(
                        'rentalDamageStdEndOk',
                        51,
                        'American Travel Survey v1'
                      );
                    "
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-dialog>
            </v-col>
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center">
          <v-col
            cols="12"
            sm="11"
            md="9"
            lg="9"
            class="d-inline-flex pa-0"
          >
            <v-col
              class="d-flex justify-center border-right"
              style="background-color: white;"
              cols="4"
            >
            </v-col>
            <v-col
              class="d-flex justify-center border-right"
              style="background-color: white;"
              cols="4"
            >
              <div
                class="plan-title text-center font-weight-bold primary--text"
              >
                ${{ rentalCostPref }}
              </div>
            </v-col>
            <v-col
              class="d-flex justify-center border-right"
              style="background-color: white;"
              cols="4"
            >
              <div class="plan-title text-center font-weight-bold travel--text">
                ${{ rentalCostStd }}
              </div>
            </v-col>
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center mt-2">
          <v-col
            cols="12"
            sm="11"
            md="9"
            lg="9"
            class="d-inline-flex pa-0"
          >
            <v-col
              class="d-flex justify-center border-right"
              style="background-color: white;"
              cols="4"
            >
            </v-col>
            <v-col
              class="d-flex justify-center border-right"
              style="background-color: white;"
              cols="4"
            >
              <div class="d-flex justify-center text-center">
                Add to Plan
              </div>
            </v-col>
            <v-col
              class="d-flex justify-center"
              style="background-color: white;"
              cols="4"
            >
              <div class="d-flex justify-center text-center">
                Add to Plan
              </div>
            </v-col>
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center mt-0">
          <v-col
            cols="12"
            sm="11"
            md="9"
            lg="9"
            class="d-inline-flex pa-0"
          >
            <v-col
              class="d-flex justify-center border-right"
              style="background-color: white;"
              cols="4"
            >
            </v-col>
            <v-col
              class="d-flex justify-center border-right"
              style="background-color: white;"
              cols="4"
            >
              <v-checkbox
                v-model="rentalCarPref"
                :disabled="!startDatePref && !endDatePref"
                @click="
                  clickedFieldGlobal(
                    'rentalDamagePrefChckbox',
                    52,
                    'American Travel Survey v1'
                  )
                "
              >
              </v-checkbox>
            </v-col>
            <v-col
              class="d-flex justify-center"
              style="background-color: white;"
              cols="4"
            >
              <v-checkbox
                v-model="rentalCarStd"
                :disabled="!startDateStd || !endDateStd"
                @click="
                  clickedFieldGlobal(
                    'rentalDamageStdChckbox',
                    53,
                    'American Travel Survey v1'
                  )
                "
              >
              </v-checkbox>
            </v-col>
          </v-col>
        </v-row>
      </div>
      <!----------------------------------------Trip Cancellation for Any Reason---------------------------------->
      <div v-if="travelInfo.living !== 'New York' && departDateDiff >= 4">
        <v-row class="d-flex justify-center">
          <v-col
            cols="12"
            sm="11"
            md="9"
            lg="9"
            class="d-flex justify-center neutral"
            style="border-top: 1px solid #e0e0e0;"
          >
            <div :class="mobileDevice ? 'covd-title-mobile' : 'covd-title'">
              Trip Cancellation for Any Reason
            </div>
            <div class="help">
              <v-tooltip
                top
                color="secondary"
                max-width="250px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="primary"
                    dark
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-help-circle
                  </v-icon>
                </template>
                <span>Cancel for any reason is ideal if you're concerned about
                  canceling a trip due to changing travel restrictions,
                  pre-existing medical conditions not covered by our Premium
                  Plan, pregnancy, work related events such as losing a new job,
                  or other events that are not covered by the plans.</span>
              </v-tooltip>
            </div>
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center">
          <v-col
            cols="12"
            sm="11"
            md="9"
            lg="9"
            class="d-flex justify-center neutral text-h5 text-center"
            style="border-bottom: 1px solid #e0e0e0;"
          >
            Reimburses you up to 60% of the penalty amount when you cancel for
            any reason. Available as an optional upgrade to the Premium plan if
            purchased within 24 hours of initial deposit and other requirements
            are met. Please see a sample Description of Coverage or Policy for
            details. Not available to residents of New York.
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center">
          <v-col
            cols="12"
            sm="11"
            md="9"
            lg="9"
            class="d-inline-flex pa-0"
          >
            <v-col
              class="d-flex justify-center border-right"
              style="background-color: white;"
              cols="4"
            >
              <div
                class="d-flex justify-center text-center secondary--text plan-title font-weight-black"
              >
                60%
              </div>
            </v-col><v-col
              class="d-flex justify-center border-right"
              style="background-color: white;"
              cols="4"
            >
            </v-col><v-col
              class="d-flex justify-center"
              style="background-color: white;"
              cols="4"
            >
            </v-col>
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center">
          <v-col
            cols="12"
            sm="11"
            md="9"
            lg="9"
            class="d-inline-flex pa-0"
          >
            <v-col
              class="d-flex justify-center border-right"
              style="background-color: white;"
              cols="4"
            >
              <div class="d-flex justify-center text-center">
                of the penalty amount
              </div>
            </v-col>
            <v-col
              class="d-flex justify-center border-right"
              style="background-color: white;"
              cols="4"
            >
            </v-col>
            <v-col
              class="d-flex justify-center"
              style="background-color: white;"
              cols="4"
            >
            </v-col>
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center mt-0">
          <v-col
            cols="12"
            sm="11"
            md="9"
            lg="9"
            class="d-inline-flex pa-0"
          >
            <v-col
              class="d-flex justify-center border-right"
              style="background-color: white;"
              cols="4"
            >
              <div class="d-flex justify-center text-center font-weight-bold">
                <a
                  href="https://www.generalitravelinsurance.com/travel-resources/cfar-worth-it.html#choose"
                  target="_new"
                  @click="
                    clickedFieldGlobal(
                      'tripCancellationLink',
                      54,
                      'American Travel Survey v1'
                    )
                  "
                >Is Cancel for any reason right for me?</a>
              </div>
            </v-col>
            <v-col
              class="d-flex justify-center border-right"
              style="background-color: white;"
              cols="4"
            >
            </v-col>
            <v-col
              class="d-flex justify-center"
              style="background-color: white;"
              cols="4"
            >
            </v-col>
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center">
          <v-col
            cols="12"
            sm="11"
            md="9"
            lg="9"
            class="d-inline-flex pa-0"
          >
            <v-col
              class="d-flex justify-center border-right"
              style="background-color: white;"
              cols="4"
            >
              <div
                class="d-flex justify-center text-center font-weight-bold mx-12"
              >
                Initial Deposit Date
              </div>
              <div>
                <v-tooltip
                  top
                  color="secondary"
                  max-width="250px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      color="secondary"
                      dark
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-help-circle
                    </v-icon>
                  </template>
                  <span>Enter your initial deposit date. This optional coverage is
                    available if purchased within 24 hours of initial
                    deposit.</span>
                </v-tooltip>
              </div>
            </v-col><v-col
              class="d-flex justify-center border-right"
              style="background-color: white;"
              cols="4"
            >
            </v-col><v-col
              class="d-flex justify-center"
              style="background-color: white;"
              cols="4"
            >
            </v-col>
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center mt-0">
          <v-col
            cols="12"
            sm="11"
            md="9"
            lg="9"
            class="d-inline-flex pa-0"
          >
            <v-col
              class="d-flex justify-center border-right"
              style="background-color: white;"
              cols="4"
            >
              <v-dialog
                ref="dialog5"
                v-model="modal5"
                :return-value.sync="depositDate"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Deposit Date"
                    rules="required"
                  >
                    <v-text-field
                      v-model="depositDate"
                      :error-messages="errors"
                      outlined
                      v-bind="attrs"
                      v-on="on"
                      @click="
                        clickedFieldGlobal(
                          'tripCancellationIntDeposDate',
                          55,
                          'American Travel Survey v1'
                        )
                      "
                    ></v-text-field>
                  </validation-provider>
                </template>
                <v-date-picker
                  v-model="depositDate"
                  scrollable
                  :min="minCancellationDate"
                  :max="maxCancellationDate"
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    color="primary"
                    @click="
                      modal5 = false;
                      clickedFieldGlobal(
                        'tripCancellationCancelBtn',
                        56,
                        'American Travel Survey v1'
                      );
                    "
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="
                      $refs.dialog5.save(depositDate);
                      clickedFieldGlobal(
                        'tripCancellationOkBtn',
                        57,
                        'American Travel Survey v1'
                      );
                    "
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-dialog>
            </v-col><v-col
              class="d-flex justify-center border-right"
              style="background-color: white;"
              cols="4"
            >
              <div class="d-flex justify-center text-center">
                <v-icon
                  x-large
                  color="primary"
                >
                  mdi-close
                </v-icon>
              </div>
            </v-col><v-col
              class="d-flex justify-center"
              style="background-color: white;"
              cols="4"
            >
              <div class="d-flex justify-center text-center">
                <v-icon
                  x-large
                  color="travel"
                >
                  mdi-close
                </v-icon>
              </div>
            </v-col>
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center">
          <v-col
            cols="12"
            sm="11"
            md="9"
            lg="9"
            class="d-inline-flex pa-0"
          >
            <v-col
              class="d-flex justify-center border-right"
              style="background-color: white;"
              cols="4"
            >
              <div
                class="d-flex justify-center text-center secondary--text plan-title font-weight-black"
              >
                ${{ CFARCost }}
              </div>
            </v-col><v-col
              class="d-flex justify-center border-right"
              style="background-color: white;"
              cols="4"
            >
            </v-col><v-col
              class="d-flex justify-center"
              style="background-color: white;"
              cols="4"
            >
            </v-col>
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center mt-2">
          <v-col
            cols="12"
            sm="11"
            md="9"
            lg="9"
            class="d-inline-flex pa-0"
          >
            <v-col
              class="d-flex justify-center border-right"
              style="background-color: white;"
              cols="4"
            >
              <div class="d-flex justify-center text-center">
                Add to Plan
              </div>
            </v-col><v-col
              class="d-flex justify-center border-right"
              style="background-color: white;"
              cols="4"
            >
            </v-col><v-col
              class="d-flex justify-center"
              style="background-color: white;"
              cols="4"
            >
            </v-col>
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center mt-0">
          <v-col
            cols="12"
            sm="11"
            md="9"
            lg="9"
            class="d-inline-flex pa-0"
          >
            <v-col
              class="d-flex justify-center border-right"
              style="background-color: white;"
              cols="4"
            >
              <v-checkbox
                v-model="tripCancellation"
                @click="
                  clickedFieldGlobal(
                    'tripCancellationChckbox',
                    58,
                    'American Travel Survey v1'
                  )
                "
              >
              </v-checkbox>
            </v-col>
            <v-col
              class="d-flex justify-center border-right"
              style="background-color: white;"
              cols="4"
            >
            </v-col>
            <v-col
              class="d-flex justify-center"
              style="background-color: white;"
              cols="4"
            >
            </v-col>
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center mt-0">
          <v-col
            cols="12"
            sm="11"
            md="9"
            lg="9"
            class="d-inline-flex pa-0"
          >
            <v-col
              class="d-flex justify-center border-right"
              style="background-color: white;"
              cols="4"
            >
              <div class="d-flex justify-center text-center">
                Purchase requirements apply
              </div>
            </v-col><v-col
              class="d-flex justify-center border-right"
              style="background-color: white;"
              cols="4"
            >
            </v-col><v-col
              class="d-flex justify-center"
              style="background-color: white;"
              cols="4"
            >
            </v-col>
          </v-col>
        </v-row>
      </div>
      <!----------------------------------------Plan Disclosures---------------------------------->
      <v-row
        id="disclosures"
        class="d-flex justify-center mt-16"
      >
        <v-col
          cols="12"
          sm="11"
          md="9"
          lg="9"
          class="d-flex justify-center neutral text-center"
          :class="mobileDevice ? 'covd-title-mobile' : 'covd-title'"
          style="border: 1px solid #e0e0e0;"
        >
          Plan Disclosures
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center">
        <v-col
          cols="11"
          sm="11"
          md="9"
          lg="9"
          class="text-center pa-8"
          :class="mobileDevice ? 'covid-sub-mobile' : 'covid-sub'"
          style="border: 1px solid #e0e0e0;"
        >
          Travel insurance plans on this website are only available to U.S.
          residents. Generali Global Assistance is unable to provide coverage
          for travel to Russia, Belarus, Crimea, and the Donetsk and Luhansk
          People’s regions, Cuba, Iran, North Korea, Myanmar, Afghanistan and
          Syria. <br />
          <br />
          For any of our U.S. customers traveling in Ukraine, please be advised
          that Generali's assistance capabilities in this country are
          substantially limited at this time.
        </v-col>
      </v-row>
      <!----------------------------------------Quote---------------------------------->
      <v-row justify="center">
        <v-col
          class="d-flex justify-center font-weight-bold mb-8 pb-0 mt-8"
          :class="mobileDevice ? 'covg-title-mobile' : 'covg-title'"
        >
          Your Quote
        </v-col>
      </v-row>
      <!-- <v-row class="d-flex justify-center mb-4">
        <v-col
          cols="12"
          sm="11"
          md="9"
          lg="9"
          class="d-inline-flex justify-center text-center"
        >
          <v-col
            cols="3"
            class="d-flex justify-center border-top-left"
          >
            <v-btn
              color="secondary"
              :x-large="!mobileDevice"
              block
              @click="submit('premium', $premiumPrice)"
            >
              Buy Premium
            </v-btn>
          </v-col><v-col
            cols="3"
            class="d-flex justify-center border-top-left"
          >
            <v-btn
              color="primary"
              :x-large="!mobileDevice"
              block
              @click="submit('preferred', $preferredPrice)"
            >
              Buy Preferred
            </v-btn>
          </v-col><v-col
            cols="3"
            class="d-flex justify-center border-top-left"
          >
            <v-btn
              color="travel"
              :x-large="!mobileDevice"
              block
              @click="submit('standard', $standardPrice)"
            >
              Buy Standard
            </v-btn>
          </v-col>
        </v-col>
      </v-row> -->
      <v-row class="d-flex justify-center mt-0">
        <v-col
          cols="12"
          sm="11"
          md="9"
          lg="9"
          class="d-flex justify-space-between"
        >
          <v-card
            v-model="premium"
            class="pa-4 font-weight-bold secondary white--text"
            outlined
            width="32%"
            @click="
              submit('premium', premiumTotal);
              clickedFieldGlobal(
                'premiumPlanCard',
                59,
                'American Travel Survey v1'
              );
            "
          >
            <div :class="mobileDevice ? 'plan-title-mobile' : 'plan-title'">
              Buy <br />
              Premium
            </div>
            <div :class="mobileDevice ? 'plan-title-mobile' : 'plan-title'">
              ${{ premiumTotal }}
            </div>
            <div :class="mobileDevice ? 'plan-sub-mobile' : 'plan-sub'">
              total cost
            </div>
          </v-card>
          <v-card
            v-model="preferred"
            class="pa-4 font-weight-bold primary white--text"
            outlined
            width="32%"
            @click="
              submit('preferred', prefTotal);
              clickedFieldGlobal(
                'preferredPlanCard',
                60,
                'American Travel Survey v1'
              );
            "
          >
            <div :class="mobileDevice ? 'plan-title-mobile' : 'plan-title'">
              Buy <br />
              Preferred
            </div>
            <div :class="mobileDevice ? 'plan-title-mobile' : 'plan-title'">
              ${{ prefTotal }}
            </div>
            <div :class="mobileDevice ? 'plan-sub-mobile' : 'plan-sub'">
              total cost
            </div>
          </v-card>
          <v-card
            v-model="standard"
            class=" pa-4 font-weight-bold travel white--text"
            outlined
            width="32%"
            @click="
              submit('standard', stdTotal);
              clickedFieldGlobal(
                'standardPlanCard',
                61,
                'American Travel Survey v1'
              );
            "
          >
            <div :class="mobileDevice ? 'plan-title-mobile' : 'plan-title'">
              Buy <br />
              Standard
            </div>
            <div :class="mobileDevice ? 'plan-title-mobile' : 'plan-title'">
              ${{ stdTotal }}
            </div>
            <div :class="mobileDevice ? 'plan-sub-mobile' : 'plan-sub'">
              total cost
            </div>
          </v-card>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center">
        <v-col
          class="d-flex justify-center"
          cols="4"
          md="3"
        >
          <div class="text-center">
            <a
              class="font-weight-bold text-center"
              href="https://www.csatravelprotection.com/certpolicy.do?product=GGA300"
              target="_new"
            >
              <span
                class="underline primary--text"
                :class="mobileDevice ? 'covg-txt-mobile' : ''"
                @click="
                  clickedFieldGlobal(
                    'policyDescriptionLinkPremium',
                    62,
                    'American Travel Survey v1'
                  )
                "
              >View Description of Coverage/Policy</span>
            </a>
          </div>
        </v-col>
        <v-col
          class="d-flex justify-center"
          cols="4"
          md="3"
        >
          <div class="text-center">
            <a
              class="font-weight-bold text-center"
              href="https://www.csatravelprotection.com/certpolicy.do?product=GGA200"
              target="_new"
            >
              <span
                class="underline primary--text"
                :class="mobileDevice ? 'covg-txt-mobile' : ''"
                @click="
                  clickedFieldGlobal(
                    'policyDescriptionLinkPreferred',
                    63,
                    'American Travel Survey v1'
                  )
                "
              >View Description of Coverage/Policy</span>
            </a>
          </div>
        </v-col>
        <v-col
          class="d-flex justify-center"
          cols="4"
          md="3"
        >
          <div class="text-center">
            <a
              class="font-weight-bold text-center"
              href="https://www.csatravelprotection.com/certpolicy.do?product=GGA100"
              target="_new"
            >
              <span
                class="underline primary--text"
                :class="mobileDevice ? 'covg-txt-mobile' : ''"
                @click="
                  clickedFieldGlobal(
                    'policyDescriptionLinkStandard',
                    64,
                    'American Travel Survey v1'
                  )
                "
              >View Description of Coverage/Policy</span>
            </a>
          </div>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center">
        <v-col cols="9">
          <v-divider class="thick-divider"></v-divider>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center pb-16 text-center">
        <v-col cols="6">
          <div>
            The plan cost listed includes the cost of travel insurance premium and an assistance service fee. Travel insurance coverages are underwritten by: Generali U.S Branch, New York, NY; NAIC#11231, for the operating name used in certain states, please see
            <a
              href="https://www.generalitravelinsurance.com/customer/disclosures.html"
              target="_new"
              @click="
                clickedFieldGlobal(
                  'importantDisclosureLink',
                  65,
                  'American Travel Survey v1'
                )
              "
            >Important Disclosures</a>.
          </div>
        </v-col>
      </v-row>
    </div>
    <!-- <v-row class="d-flex justify-center">
      <v-col
        cols="3"
      >
        <v-btn
          x-large
          block
          color="#005F6C"
          class="white--text"
          :disabled="selectedPlan === ''"
          to="/travelpay"
        >
          PURCHASE {{ selectedPlan }}
        </v-btn>
      </v-col>
    </v-row> -->
  </v-container>
</template>

<script>
  import moment from 'moment'
  import EllipseProgress from '../components/EllipseProgress'
  import idleMixin from '../../../mixins/IdleMixin'
  var numeral = require('numeral')
  export default {
    components: {
      EllipseProgress
    },
    mixins: [idleMixin],
    data () {
      return {
        src: '../../../assets/Travel-Icon.png',
        rentalCarPref: false,
        rentalCarStd: false,
        startDatePref: '',
        endDatePref: '',
        startDateStd: '',
        endDateStd: '',
        premium: '',
        preferred: '',
        standard: '',
        standardRentalDamage: '',
        preferredRentalDamage: '',
        tripCancellation: '',
        depositDate: moment().format('YYYY-MM-DD'),
        productclass: '',
        modal1: null,
        modal2: null,
        modal3: null,
        modal4: null,
        modal5: null,
        selectedPlan: '',
        price: '',
        plan: ''
      }
    },
    computed: {
      rentalCostPref () {
        if (this.startDatePref && this.endDatePref) {
          return numeral(this.$store.getters.getRentalDamagePricePref).format(
            '0.00'
          )
        }
        return 0
      },
      rentalCostStd () {
        if (this.startDateStd && this.endDateStd) {
          return numeral(this.$store.getters.getRentalDamagePriceStd).format(
            '0.00'
          )
        }
        return 0
      },
      maxCancellationDate () {
        return moment(this.travelInfo.departdate)
          .subtract(1, 'day')
          .format('YYYY-MM-DD')
      },
      minCancellationDate () {
        return moment().format('YYYY-MM-DD')
      },
      premiumTotal () {
        if (this.tripCancellation) {
          let sum =
            Number(this.quoteObject.largequote.quoteresponse.price[0]) +
            Number(this.$store.getters.getCFARCost)
          return numeral(sum).format('0.00')
        } else {
          return numeral(this.premPrice).format('0.00')
        }
      },
      prefTotal () {
        if (this.rentalCarPref) {
          let sum =
            Number(this.quoteObject.mediumquote.quoteresponse.price[0]) +
            Number(this.rentalCostPref)
          return numeral(sum).format('0.00')
        } else {
          return this.prefPrice
        }
      },
      stdTotal () {
        if (this.rentalCarStd) {
          let sum =
            Number(this.quoteObject.smallquote.quoteresponse.price[0]) +
            Number(this.rentalCostStd)
          return numeral(sum).format('0.00')
        } else {
          return this.stndPrice
        }
      },
      CFARCost () {
        return numeral(this.$store.getters.getCFARCost).format('0.00')
      },
      departDateDiff () {
        let depart = this.travelInfo.departdate
        console.log(depart)
        let today = moment().format('YYYY-MM-DD')
        console.log(today)
        return moment(depart).diff(today, 'days')
      },
      mobileDevice () {
        return this.$vuetify.breakpoint.xsOnly
      },
      tabletDevice () {
        return this.$vuetify.breakpoint.smOnly
      },
      desktopDevice () {
        return this.$vuetify.breakpoint.mdAndUp
      },
      loading () {
        return this.$store.getters.getloading
      },
      quoteObject () {
        return this.$store.getters.getUSATravelQuote
      },
      premPrice () {
        return numeral(this.quoteObject.largequote.quoteresponse.price[0]).format(
          '0.00'
        )
      },
      prefPrice () {
        return numeral(
          this.quoteObject.mediumquote.quoteresponse.price[0]
        ).format('0.00')
      },
      stndPrice () {
        return numeral(this.quoteObject.smallquote.quoteresponse.price[0]).format(
          '0.00'
        )
      },
      premiumCost () {
        return Number(this.premiumTotal)
      },
      preferredCost () {
        return Number(this.prefTotal)
      },
      standardCost () {
        return Number(this.stdTotal)
      },
      premiumQuote () {
        return this.quoteObject.largequote
      },
      preferredQuote () {
        return this.quoteObject.mediumquote
      },
      standardQuote () {
        return this.quoteObject.smallquote
      },
      premiumSelected () {
        this.productclass = 'GG300'
        return this.selectedPlan === 'Premium'
      },
      preferredSelected () {
        this.productclass = 'GGA200'
        return this.selectedPlan === 'Preferred'
      },
      standardSelected () {
        return this.selectedPlan === 'Standard'
      },
      travelInfo () {
        return this.$store.getters.getUSATravelDetails
      },
      travelCoverageDetails () {
        let usatraveldetails = this.$store.getters.getUSATravelDetails
        console.log(
          'usa travel details from travel select plan usa ',
          usatraveldetails
        )
        return {
          plan: this.plan,
          price: this.price,
          carrier: 'Generali',
          productclass: this.productclass,
          cost: usatraveldetails.cost,
          numinsured: usatraveldetails.numinsured,
          departdate: usatraveldetails.departdate,
          returndate: usatraveldetails.returndate,
          triptype: usatraveldetails.triptype,
          destination: usatraveldetails.destination,
          tripcost: usatraveldetails.tripcost,
          travelers: usatraveldetails.travelers,
          age: usatraveldetails.age,
          living: usatraveldetails.living
        }
      }
    },
    created () {
      this.startDatePref = this.travelInfo.departdate
      this.startDateStd = this.travelInfo.departdate
    },
    mounted () {
      console.log(this.travelCoverageDetails)
      if (!this.travelCoverageDetails.numinsured) {
        this.$router.push({ path: '/travel/infousa' })
      }
      console.log(this.departDateDiff, 'DEPART DATE DIFF')
      console.log(this.CFARCost, 'CFAR COST')
    },
    methods: {
      setEndDatePref () {
        this.endDatePref = moment(this.startDatePref)
          .add(1, 'day')
          .format('YYYY-MM-DD')
      },
      setEndDateStd () {
        this.endDateStd = moment(this.startDateStd)
          .add(1, 'day')
          .format('YYYY-MM-DD')
      },
      rentalDamagePref () {
        if (this.startDatePref && this.endDatePref) {
          let payload = {
            numinsured: this.travelInfo.numinsured,
            departdate: this.travelInfo.departdate,
            returndate: this.travelInfo.returndate,
            triptype: this.travelInfo.triptype,
            destination: this.travelInfo.destination,
            tripcost: this.travelInfo.tripcost,
            age: this.travelInfo.age,
            startDate: this.startDatePref,
            endDate: this.endDatePref
          }
          console.log(payload, 'PAYLOAD')
          this.$store.dispatch('getRentalCarCost', payload).then(() => {
            this.$store.dispatch('setRentalDamagePricePref')
          })
        }
      },
      rentalDamageStd () {
        if (this.startDateStd && this.endDateStd) {
          let payload = {
            numinsured: this.travelInfo.numinsured,
            departdate: this.travelInfo.departdate,
            returndate: this.travelInfo.returndate,
            triptype: this.travelInfo.triptype,
            destination: this.travelInfo.destination,
            tripcost: this.travelInfo.tripcost,
            age: this.travelInfo.age,
            startDate: this.startDateStd,
            endDate: this.endDateStd
          }
          console.log(payload, 'PAYLOAD')
          this.$store.dispatch('getRentalCarCost', payload).then(() => {
            this.$store.dispatch('setRentalDamagePriceStd')
          })
        }
      },
      scrollToDisclosures () {
        let element = document.getElementById('disclosures')
        element.scrollIntoView({ behavior: 'smooth', block: 'start' })
      },
      submit (plan, price) {
        this.productclass = ''
        this.plan = plan
        this.price = price
        if (this.plan === 'premium') {
          this.productclass = 'GGA300'
        } else if (this.plan === 'preferred') {
          this.productclass = 'GGA200'
        } else if (this.plan === 'standard') {
          this.productclass = 'GGA100'
        }
        if (this.tripCancellation && plan === 'premium') {
          Object.assign(this.travelCoverageDetails, {
            options: {
              cancelAnyReason: {
                initialPaymentDate: this.depositDate
              }
            }
          })
        }
        if (this.rentalCarPref && plan === 'preferred') {
          Object.assign(this.travelCoverageDetails, {
            options: {
              collisionDamageWaiver: {
                startDate: this.startDatePref,
                endDate: this.endDatePref
              }
            }
          })
        }
        if (this.rentalCarStd && plan === 'standard') {
          Object.assign(this.travelCoverageDetails, {
            options: {
              collisionDamageWaiver: {
                startDate: this.startDateStd,
                endDate: this.endDateStd
              }
            }
          })
        }
        this.$gtag.event('TravelSelectPlan', { action: 'checkout' })
        // this.$store.dispatch('setTravelCoverageDetails', this.travelCoverageDetails)
        this.$store.dispatch('setUSATravelDetails', this.travelCoverageDetails)

        this.$router.push({ path: '/travel/payusa' })
      }
    }
  }
</script>

<style lang="scss" scoped>
.container {
  min-height: 80vh;
}
.covg-txt-mobile {
  font-family: roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 132.19%;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.04em;
  text-decoration-line: underline;
  text-transform: capitalize;
  color: #00a1b7;
}
.covg-txt {
  font-family: roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 132.19%;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.04em;
  text-decoration-line: underline;
  text-transform: capitalize;
  color: #00a1b7;
}
.plan-details-mobile {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 132.19%;
  text-align: center;
  color: #616161;
}
.plan-title-mobile {
  font-family: forma-djr-deck, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 132.19%;
  text-align: center;
}
.plan-title {
  font-family: forma-djr-deck, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 33px;
  line-height: 132.19%;
  text-align: center;
}
.plan-sub-mobile {
  font-family: roboto, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;
}
.plan-sub {
  font-family: roboto, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
}
.edit-txt {
  font-family: roboto, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0.00892857em;
  text-transform: uppercase;
  color: #00a1b7;
}
.edit-txt-mobile {
  font-family: roboto, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  display: flex;
  align-items: center;
  letter-spacing: 0.00892857em;
  text-transform: uppercase;
  color: #00a1b7;
}
.title-text-mobile {
  font-family: forma-djr-deck, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 132.19%;
  text-transform: capitalize;
  color: #757575;
}
.title-text-tablet {
  font-family: forma-djr-deck, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 132.19%;
  color: #757575;
}
.title-text {
  font-family: forma-djr-deck, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 132.19%;
  color: #757575;
}
.plan-title {
  font-size: 33px;
}
.selected {
  background-color: #005f6c;
  color: white;
}
.premium {
  background-color: #005f6c;
}
.preferred {
  background-color: #00a1b7;
}
.standard {
  background-color: #46c5d6;
}
.neutral {
  background-color: #f5f5f5;
  color: #005f6c;
  font-size: 18px;
  font-weight: bold;
  position: relative;
}
.help {
  position: absolute;
  right: 2rem;
}
.neutral-small {
  background-color: #f5f5f5;
  color: black;
}
.transparent {
  font-size: 26px;
  font-weight: bold;
}
.thick-divider {
  border-width: 4px !important;
  border-color: #005f6c;
}

.border-right {
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}

.checkbox {
  height: 20px !important;
}

.buy-now {
  font-size: 26px;
  font-weight: bold;
}

.secondary-border {
  border: 1px solid #005f6c !important;
}

.primary-border {
  border: 1px solid #00a1b7 !important;
}

.tertiary-border {
  border: 1px solid #46c5d6 !important;
}

.underline {
  text-decoration: underline;
}

.covg-title {
  font-family: forma-djr-deck, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 33px;
  line-height: 132.19%;
  /* identical to box height, or 24px */

  text-align: center;
  text-transform: capitalize;

  /* Gray/600 */

  color: #757575;
}

.covg-title-mobile {
  font-family: forma-djr-deck, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 132.19%;
  /* identical to box height, or 24px */

  text-align: center;
  text-transform: capitalize;

  /* Gray/600 */

  color: #757575;
}
.undwr-txt {
  font-family: roboto, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 132.19%;
  /* or 13px */

  display: flex;
  align-items: center;
  letter-spacing: -0.04em;
  text-transform: capitalize;

  /* Gray/800 */

  color: #424242;
}
.undwr-txt-mobile {
  font-family: roboto, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 132.19%;
  /* or 13px */

  display: flex;
  align-items: center;
  letter-spacing: -0.04em;
  text-transform: capitalize;

  /* Gray/800 */

  color: #424242;
}

.plan-type-mobile {
  font-family: forma-djr-deck, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 132.19%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
}

.plan-type {
  font-family: forma-djr-deck, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 33px;
  line-height: 132.19%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
}

.covid-sub {
  font-family: roboto, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 132.19%;
  /* or 15px */

  text-align: center;
  letter-spacing: -0.04em;

  /* Gray/700 */

  color: #616161;
}
.covid-sub-mobile {
  font-family: roboto, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 132.19%;
  text-align: center;
  letter-spacing: -0.04em;
  color: #616161;
}

.covd-title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 22px;
  /* or 169% */

  display: flex;
  align-items: center;
  text-align: center;

  /* Gray/600 */

  color: #757575;
}

.covd-title-mobile {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  /* or 169% */

  display: flex;
  align-items: center;
  text-align: center;

  /* Gray/600 */

  color: #757575;
}
</style>
